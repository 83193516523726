import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DashboardState, Dictionary } from 'types'
import {
	PackageInterval,
	PackagePricing,
	PackagesFeature,
	PackagesFeaturesTableV2,
	PackagesType,
} from 'shared/models/Package'
import { normalize } from 'utils'

import { packagesApi } from './api'

export type PackagesRootState = Pick<DashboardState, 'packages'>
export type PackagesState = typeof initialState

const DEFAULT_INTERVAL = PackageInterval.Year

export const initialState = {
	isFetching: false,
	isError: false,
	isFetchingFeatureSet: false,
	isFetchingFeatureSetV2: false,
	packages: {} as Dictionary<PackagePricing>,
	featureSet: null as null | PackagesFeature[],
	featureSetV2: null as null | PackagesFeaturesTableV2,
	showConfirmFreeModal: false,
	interval: DEFAULT_INTERVAL as PackageInterval,
	selectedPackageType: PackagesType.Livechat,
}

export const fetchPackagesFeatureSet = createAsyncThunk('packages_feature_set/FETCH', () => {
	return packagesApi.getPackagesFeatureSet()
})

export const fetchPackagesFeatureSetV2 = createAsyncThunk('packages_feature_set_v2/FETCH', () => {
	return packagesApi.getPackagesFeatureSetV2()
})

export const fetchPackages = createAsyncThunk('packages/FETCH', () => {
	return packagesApi.getPackages()
})

const slice = createSlice({
	name: 'packages',
	initialState,
	reducers: {
		showConfirmFreeModal: (state) => {
			state.showConfirmFreeModal = true
		},
		hideConfirmFreeModal: (state) => {
			state.showConfirmFreeModal = false
		},
		setPackagesInterval: (state, { payload }: PayloadAction<PackageInterval>) => {
			state.interval = payload
		},
		setSelectedPackageType: (state, { payload }: PayloadAction<PackagesType>) => {
			state.selectedPackageType = payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchPackages.pending, (state) => {
			state.isFetching = true
			state.isError = false
		})
		builder.addCase(fetchPackages.fulfilled, (state, { payload }) => {
			state.isFetching = false
			state.isError = false
			state.packages = normalize('name', payload)
		})
		builder.addCase(fetchPackages.rejected, (state) => {
			state.isFetching = false
			state.isError = true
		})

		builder.addCase(fetchPackagesFeatureSet.pending, (state) => {
			state.isFetchingFeatureSet = true
		})
		builder.addCase(fetchPackagesFeatureSet.fulfilled, (state, { payload }) => {
			state.isFetchingFeatureSet = false
			state.featureSet = payload
		})
		builder.addCase(fetchPackagesFeatureSet.rejected, (state) => {
			state.isFetchingFeatureSet = false
		})

		builder.addCase(fetchPackagesFeatureSetV2.pending, (state) => {
			state.isFetchingFeatureSetV2 = true
		})
		builder.addCase(fetchPackagesFeatureSetV2.fulfilled, (state, { payload }) => {
			state.isFetchingFeatureSetV2 = false
			state.featureSetV2 = payload
		})
		builder.addCase(fetchPackagesFeatureSetV2.rejected, (state) => {
			state.isFetchingFeatureSetV2 = false
		})
	},
})

const { reducer, actions } = slice
export const { showConfirmFreeModal, hideConfirmFreeModal, setPackagesInterval, setSelectedPackageType } = actions
export default reducer
