import { normalizeDiacritics } from 'normalize-text'

import { AllowedPaymentGateways, PackageInterval, PackageIntervalAvailable, PackageName } from 'shared/models/Package'

import { MAX_CHECKOUT_AGENTS, MIN_CHECKOUT_AGENTS } from './constants'
import { CheckoutAllowedIntervals, CheckoutAllowedPackageName } from './types'

export const isCheckoutPackageValid = (packageName?: PackageName): packageName is CheckoutAllowedPackageName => {
	return !!packageName && (packageName === PackageName.Basic || packageName === PackageName.Pro)
}

export const isCheckoutIntervalValid = (interval: PackageInterval): interval is PackageIntervalAvailable => {
	return interval === PackageInterval.Month || interval === PackageInterval.Year
}

export const isCheckoutAgentsCountValid = (agents: number | null | undefined): agents is number => {
	if (!agents) return false
	return agents >= MIN_CHECKOUT_AGENTS && agents <= MAX_CHECKOUT_AGENTS
}

export const isCheckoutChatbotConversationsCountValid = (value: number | null, name: PackageName): value is number => {
	return value !== null && name !== PackageName.Trial
}

export const isCheckoutLivechatConversationsCountValid = (value: number | null, name: PackageName): value is number => {
	return value !== null && name !== PackageName.Trial
}

export const isCheckoutAiConversationsCountValid = (value: number | null, name: PackageName): value is number => {
	return value !== null && name !== PackageName.Trial
}

export const getAllowedCheckoutIntervalsFromGateways = (
	allowedGateways: AllowedPaymentGateways,
): CheckoutAllowedIntervals => {
	const allowedIntervals: Record<PackageIntervalAvailable, boolean> = {
		[PackageInterval.Month]: false,
		[PackageInterval.Year]: false,
	}

	Object.values(allowedGateways).forEach((gw) => {
		gw.intervals.forEach((interval: PackageIntervalAvailable) => {
			allowedIntervals[interval] = true
		})
	})

	return allowedIntervals
}

export const getDefaultAllowedCheckoutInterval = (intervals: CheckoutAllowedIntervals): PackageIntervalAvailable => {
	if (intervals[PackageInterval.Month]) return PackageInterval.Month
	if (intervals[PackageInterval.Year]) return PackageInterval.Year

	return PackageInterval.Year
}

export const sanitizeBraintreeParamString = (str: string): string => {
	return normalizeDiacritics(str).replaceAll(/[^ -~]+/g, '')
}

export const getAvailableBatchesArray = (min: number, max: number, batch: number) => {
	const count = (max - min) / batch + 1
	return [...Array.from({ length: count }).keys()].map((item, index) => min + index * batch)
}

export const getConversationAvailableValue = (min: number, max: number, batch: number, currentValue: number) => {
	const availableBatches = getAvailableBatchesArray(min, max, batch)
	if (!availableBatches.includes(currentValue)) {
		const newValue = availableBatches.find((item) => item > currentValue)
		return newValue ?? max
	}
	return currentValue
}
