import { batch } from 'react-redux'
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'

import {
	AgentsStatisticsAgent,
	AgentsStatisticsSummary,
	FeatureUsageName,
	MetricsResponse,
	MetricsType,
	StatisticsInterval,
} from 'models'
import { AppThunkAction, DashboardState } from 'types'
import { normalize } from 'utils'
import { accountSelectors } from 'modules/account'
import { agentsSelectors } from 'modules/agents'
import { logFeatureUsage } from 'modules/features'
import { smartHubSelectors } from 'modules/smartHub'
import {
	PartialStatisticsFilter,
	setFilter,
	setIsLongTermRange,
	shouldUpdateFilter,
	statisticsFilterSelectors,
} from 'modules/statisticsFilter'
import { fetchAggregatedTagsStatistics, fetchTagsStatisticsThunk, setTagsInterval } from 'modules/tagsStatistics'

import { statisticsApi } from './api'
import {
	DEFAULT_AGENT_METRICS,
	DEFAULT_RATING,
	DEFAULT_SUMMARY_VALUE,
	StatisticSection,
	THREE_MONTHS,
	TWO_YEARS_IN_MONTHS,
} from './constants'
import {
	ChartType,
	MetricsByChartType,
	MetricsData,
	MetricsRequestData,
	RatingResponse,
	Ratings,
	RatingsChartData,
} from './types'
import {
	addPercentagesToResult,
	autoFormatSeconds,
	calcAgentPercentageRating,
	filterEmptyResults,
	formatMetricsByAgent,
	getBucketData,
	getChartTypeByMetricsType,
	getDateFormatByInterval,
	getMaxSeriesValue,
	getRatingsRequestBody,
	getRequestBodyParams,
	getSeriesData,
	roundToTheTopTen,
	transformOnlineActivityBucket,
} from './utils'

const initialState = {
	selectedStatistic: StatisticSection.NewConversations as StatisticSection,
	agents: {
		isFetching: false,
		summary: [] as MetricsResponse[],
		error: null,
	},
	chartsData: {
		[ChartType.NewConversation]: {
			data: {} as MetricsResponse,
			interval: StatisticsInterval.Day,
			isFetching: false,
		},
		[ChartType.ServedConversation]: {
			data: {} as MetricsResponse,
			interval: StatisticsInterval.Day,
			isFetching: false,
		},
		[ChartType.ClosedConversation]: {
			data: {} as MetricsResponse,
			interval: StatisticsInterval.Day,
			isFetching: false,
		},
		[ChartType.NewContacts]: {
			data: {} as MetricsResponse,
			interval: StatisticsInterval.Day,
			isFetching: false,
		},
		[ChartType.ResponseTime]: {
			dataFirstResponseTime: {} as MetricsResponse,
			dataAvgResponseTime: {} as MetricsResponse,
			interval: StatisticsInterval.Day,
			isFetching: false,
		},
		[ChartType.Rating]: {
			data: {} as MetricsResponse,
			ratings: {
				[Ratings.Happy]: {} as MetricsData,
				[Ratings.Ok]: {} as MetricsData,
				[Ratings.Bad]: {} as MetricsData,
			},
			interval: StatisticsInterval.Day,
			isFetching: false,
		},
		[ChartType.RatingPercentage]: {
			data: {} as MetricsResponse,
			ratings: {
				[Ratings.Happy]: {} as MetricsData,
				[Ratings.Ok]: {} as MetricsData,
				[Ratings.Bad]: {} as MetricsData,
			},
			interval: StatisticsInterval.Day,
			isFetching: false,
		},
		[ChartType.OnlineActivity]: {
			data: {} as MetricsResponse,
			summaryValue: 0,
			interval: StatisticsInterval.Day,
			isFetching: false,
		},
		isInitialized: false,
		isDataValid: false,
	},
}

export const fetchAgentsMetrics = createAsyncThunk('metrics/FETCH_AGENTS', async (arg, { getState }) => {
	const state = getState() as DashboardState
	const timezone = accountSelectors.getAccountTimezone(state)
	const filter = statisticsFilterSelectors.getFilter(state)

	const agentIdsAggs = {
		type: 'terms',
		field: 'agentIds',
	}

	const agentIdAggs = {
		type: 'terms',
		field: 'agentId',
	}

	const typesAgentIdsArray = [
		{ metricsType: MetricsType.ConversationRatings },
		{ metricsType: MetricsType.NewConversation },
		{ metricsType: MetricsType.AvgTimeToClose },
		{ metricsType: MetricsType.SumTimeToClose },
	]

	const typesAgentIdArray = [
		{ metricsType: MetricsType.AvgFirstResponseTime },
		{ metricsType: MetricsType.AvgResponseTime },
	]

	const body = {
		items: [
			...getRequestBodyParams(typesAgentIdsArray, filter, timezone, agentIdsAggs),
			...getRequestBodyParams(typesAgentIdArray, filter, timezone, agentIdAggs),
		],
	}

	return statisticsApi.getMetrics(body)
})

export const fetchAllMetrics =
	(isLongTermDateRange = false): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const filter = statisticsFilterSelectors.getFilter(state)
		const timezone = accountSelectors.getAccountTimezone(state)
		const intervalNewConv = isLongTermDateRange ? StatisticsInterval.Month : getIntervalNewConversation(state)
		const intervalServedConv = isLongTermDateRange ? StatisticsInterval.Month : getIntervalServedConversation(state)
		const intervalClosedConv = isLongTermDateRange ? StatisticsInterval.Month : getIntervalClosedConversation(state)
		const intervalRating = isLongTermDateRange ? StatisticsInterval.Month : getIntervalRating(state)
		const intervalResponseTime = isLongTermDateRange ? StatisticsInterval.Month : getIntervalResponseTime(state)
		const intervalNewContacts = isLongTermDateRange ? StatisticsInterval.Month : getIntervalNewContacts(state)

		const typesArray = [
			{ metricsType: MetricsType.NewConversation, interval: intervalNewConv },
			{ metricsType: MetricsType.ServedConversation, interval: intervalServedConv },
			{ metricsType: MetricsType.ClosedConversation, interval: intervalClosedConv },
			{ metricsType: MetricsType.AvgFirstResponseTime, interval: intervalResponseTime },
			{ metricsType: MetricsType.AvgResponseTime, interval: intervalResponseTime },
			{ metricsType: MetricsType.ConversationRatings, interval: intervalRating },
			{ metricsType: MetricsType.ContactAcquired, interval: intervalNewContacts },
		]

		const data: MetricsRequestData = {
			items: typesArray,
			filter,
			timezone,
		}
		dispatch(fetchMetricsByType(data))
	}

export const fetchSmartHubMetrics = (): AppThunkAction => async (dispatch, getState) => {
	const state = getState()
	const filter = smartHubSelectors.getConversationMetricsFilter(state)
	const timezone = accountSelectors.getAccountTimezone(state)
	const withTrend = true

	const typesArray = [
		{ metricsType: MetricsType.NewConversation, interval: StatisticsInterval.Day },
		{ metricsType: MetricsType.ConversationRatingsPercent, interval: StatisticsInterval.Day },
	]

	const data: MetricsRequestData = {
		items: typesArray,
		filter,
		timezone,
		withTrend,
	}
	dispatch(fetchMetricsByType(data))
}

export const fetchOnlineTimeActivity = (): AppThunkAction => async (dispatch, getState) => {
	const state = getState()
	const filter = statisticsFilterSelectors.getFilter(state)
	const timezone = accountSelectors.getAccountTimezone(state)

	const typesArray = [
		{ metricsType: MetricsType.OnlineTime, interval: StatisticsInterval.Day },
		{ metricsType: MetricsType.Activity, interval: StatisticsInterval.Day },
	]

	const data: MetricsRequestData = {
		items: typesArray,
		filter,
		timezone,
	}
	dispatch(fetchMetricsByType(data))
}

export const fetchMetricsByType = createAsyncThunk(
	'metrics/FETCH_METRICS_BY_TYPE',
	async (data: MetricsRequestData) => {
		const aggregateOption = {
			type: 'date',
			valueFormat: 'YYYY_MM_DD:HH_mm_ss',
		}

		const body = {
			items: getRequestBodyParams(data.items, data.filter, data.timezone, aggregateOption, data.withTrend),
		}

		return statisticsApi.getMetrics(body)
	},
)

export const fetchDetailedRatings = createAsyncThunk(
	'metrics/FETCH_RATINGS',
	async (interval: StatisticsInterval, { getState }) => {
		const state = getState() as DashboardState
		const filter = statisticsFilterSelectors.getFilter(state)
		const timezone = accountSelectors.getAccountTimezone(state)

		const aggregateOption = {
			type: 'date',
			interval,
			format: getDateFormatByInterval(interval),
		}

		const body = { items: getRatingsRequestBody(filter, timezone, aggregateOption) }
		return statisticsApi.getMetricsRating(body)
	},
)

export const setFilterAndApply =
	(filter: PartialStatisticsFilter): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const storedFilter = statisticsFilterSelectors.getFilter(state)
		const selectedStatistic = getSelectedStatistic(state)
		if (!shouldUpdateFilter(storedFilter, filter)) return

		let isLongTermDateRange = statisticsFilterSelectors.getIsLongTermRange(state)
		let isLongTermOnlineTime = statisticsFilterSelectors.getIsLongTermOnlineTime(state)

		if ('from' in filter && 'to' in filter) {
			const from = moment(filter.from)
			const to = moment(filter.to)

			const diffInMonths = to.diff(from, 'months')

			isLongTermDateRange = diffInMonths > TWO_YEARS_IN_MONTHS
			isLongTermOnlineTime = diffInMonths >= THREE_MONTHS

			dispatch(setIsLongTermRange({ isLongTermDateRange, isLongTermOnlineTime }))
		}

		batch(() => {
			dispatch(setIsDataValid(false))
			dispatch(setFilter(filter))
		})

		if (selectedStatistic === StatisticSection.TagsUsage) {
			dispatch(fetchAggregatedTagsStatistics())
		} else if (selectedStatistic === StatisticSection.Agents) {
			dispatch(fetchAgentsMetrics())
		} else {
			const ratingInterval = isLongTermDateRange ? StatisticsInterval.Month : getIntervalRating(state)
			batch(() => {
				dispatch(fetchAllMetrics(isLongTermDateRange))
				dispatch(fetchDetailedRatings(ratingInterval))
				if (!isLongTermOnlineTime) {
					dispatch(fetchOnlineTimeActivity())
				}
			})
		}
		dispatch(logFeatureUsage(FeatureUsageName.StatsLastFilterUsed))
	}

export const setIntervalAndApply =
	(chartType: ChartType, interval: StatisticsInterval): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const timezone = accountSelectors.getAccountTimezone(state)
		const filter = statisticsFilterSelectors.getFilter(state)

		if (chartType === ChartType.Rating) {
			dispatch(fetchDetailedRatings(interval))
		}
		if (chartType === ChartType.Tags) {
			dispatch(setTagsInterval(interval))
			dispatch(fetchTagsStatisticsThunk())
		} else {
			const metricItems = MetricsByChartType[chartType].map((metricType) => {
				return { metricsType: metricType, interval }
			})

			const data: MetricsRequestData = {
				items: metricItems,
				filter,
				timezone,
			}
			dispatch(fetchMetricsByType(data))
		}
		dispatch(logFeatureUsage(FeatureUsageName.StatsLastFilterUsed))
	}

const statisticsSlice = createSlice({
	name: 'statistics',
	initialState,
	reducers: {
		selectStatistic(state, action) {
			state.selectedStatistic = action.payload.section
		},
		setIsDataValid(state, { payload }: PayloadAction<boolean>) {
			state.chartsData.isDataValid = payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAgentsMetrics.pending, (state) => {
				state.agents.isFetching = true
			})
			.addCase(fetchAgentsMetrics.fulfilled, (state, { payload }: PayloadAction<MetricsResponse[]>) => {
				state.agents.isFetching = false
				state.agents.summary = payload
			})
			.addCase(fetchAgentsMetrics.rejected, (state) => {
				state.agents.isFetching = false
			})

		builder
			.addCase(fetchMetricsByType.pending, (state, { meta }) => {
				const { items } = meta.arg
				items.forEach((item) => {
					state.chartsData[getChartTypeByMetricsType(item.metricsType)].isFetching = true
				})
			})
			.addCase(
				fetchMetricsByType.fulfilled,
				(
					state,
					{ payload, meta }: PayloadAction<MetricsResponse[], string, { arg: MetricsRequestData; requestId: string }>,
				) => {
					const { items } = meta.arg
					items.forEach((item) => {
						const chartType = getChartTypeByMetricsType(item.metricsType)
						state.chartsData[chartType].interval = item.interval
						state.chartsData[chartType].isFetching = false
					})

					const normalizedArray = normalize('name', payload)
					if (normalizedArray[MetricsType.NewConversation]) {
						state.chartsData[ChartType.NewConversation].data = normalizedArray[MetricsType.NewConversation]
					}
					if (normalizedArray[MetricsType.ClosedConversation]) {
						state.chartsData[ChartType.ClosedConversation].data = normalizedArray[MetricsType.ClosedConversation]
					}
					if (normalizedArray[MetricsType.ServedConversation]) {
						state.chartsData[ChartType.ServedConversation].data = normalizedArray[MetricsType.ServedConversation]
					}
					if (normalizedArray[MetricsType.AvgResponseTime] && normalizedArray[MetricsType.AvgFirstResponseTime]) {
						state.chartsData[ChartType.ResponseTime].dataFirstResponseTime =
							normalizedArray[MetricsType.AvgFirstResponseTime]
						state.chartsData[ChartType.ResponseTime].dataAvgResponseTime = normalizedArray[MetricsType.AvgResponseTime]
					}
					if (normalizedArray[MetricsType.ConversationRatings]) {
						state.chartsData[ChartType.Rating].data = normalizedArray[MetricsType.ConversationRatings]
					}
					if (normalizedArray[MetricsType.ContactAcquired]) {
						state.chartsData[ChartType.NewContacts].data = normalizedArray[MetricsType.ContactAcquired]
					}
					if (normalizedArray[MetricsType.ConversationRatingsPercent]) {
						state.chartsData[ChartType.RatingPercentage].data = normalizedArray[MetricsType.ConversationRatingsPercent]
					}
					if (normalizedArray[MetricsType.OnlineTime]) {
						state.chartsData[ChartType.OnlineActivity].summaryValue = normalizedArray[MetricsType.OnlineTime].value
					}
					if (normalizedArray[MetricsType.Activity]) {
						state.chartsData[ChartType.OnlineActivity].data = normalizedArray[MetricsType.Activity]
					}

					state.chartsData.isInitialized = true
					state.chartsData.isDataValid = true
				},
			)
			.addCase(fetchMetricsByType.rejected, (state, { meta }) => {
				const { items } = meta.arg
				items.forEach((item) => {
					state.chartsData[getChartTypeByMetricsType(item.metricsType)].isFetching = false
				})
			})

		builder
			.addCase(fetchDetailedRatings.pending, (state) => {
				state.chartsData[ChartType.Rating].isFetching = true
			})
			.addCase(
				fetchDetailedRatings.fulfilled,
				(
					state,
					{ payload, meta }: PayloadAction<RatingResponse, string, { arg: StatisticsInterval; requestId: string }>,
				) => {
					state.chartsData[ChartType.Rating].isFetching = false
					state.chartsData[ChartType.Rating].ratings = payload
					state.chartsData[ChartType.Rating].interval = meta.arg
				},
			)
			.addCase(fetchDetailedRatings.rejected, (state) => {
				state.chartsData[ChartType.Rating].isFetching = false
			})
	},
})

// Selectors
const getSelectedStatistic = (state: DashboardState) => state.statistics.selectedStatistic
const getIsInitialized = (state: DashboardState) => state.statistics.chartsData.isInitialized
const getIsDataValid = (state: DashboardState) => state.statistics.chartsData.isDataValid
const getChartsData = (state: DashboardState) => state.statistics.chartsData

const getChartDataNewConversation = (state: DashboardState) => state.statistics.chartsData[ChartType.NewConversation]
const getChartDataClosedConversation = (state: DashboardState) =>
	state.statistics.chartsData[ChartType.ClosedConversation]
const getChartDataServedConversation = (state: DashboardState) =>
	state.statistics.chartsData[ChartType.ServedConversation]
const getChartDataNewContacts = (state: DashboardState) => state.statistics.chartsData[ChartType.NewContacts]
const getChartDataResponseTime = (state: DashboardState) => state.statistics.chartsData[ChartType.ResponseTime]
const getChartDataRatings = (state: DashboardState) => state.statistics.chartsData[ChartType.Rating]
const getChartDataRatingPercentage = (state: DashboardState) => state.statistics.chartsData[ChartType.RatingPercentage]
const getChartDataOnlineActivity = (state: DashboardState) => state.statistics.chartsData[ChartType.OnlineActivity]

const isFetchingAgentsStatistics = (state: DashboardState) => state.statistics.agents.isFetching
const getAgentsSummary = (state: DashboardState) => state.statistics.agents.summary

const getDataNewConversation = createSelector(
	[getChartDataNewConversation, statisticsFilterSelectors.getFilter],
	(chartData, filter) => {
		const { data, interval } = chartData
		const bucketData = getBucketData(data.buckets, interval, filter)
		const maxValue = getMaxSeriesValue(data.buckets)
		return {
			data: getSeriesData(bucketData, MetricsType.NewConversation, 0),
			interval,
			maxValue: roundToTheTopTen(maxValue),
		}
	},
)

const getIntervalNewConversation = createSelector([getChartDataNewConversation], (chartData) => {
	return chartData.interval
})

const getSummaryNewConversation = createSelector([getChartDataNewConversation], (chartData) => {
	return chartData.data.value ?? DEFAULT_SUMMARY_VALUE
})

const getTrendNewConversation = createSelector([getChartDataNewConversation], (chartData) => {
	return chartData.data.trend ?? null
})

const getDataClosedConversation = createSelector(
	[getChartDataClosedConversation, statisticsFilterSelectors.getFilter],
	(chartData, filter) => {
		const { data, interval } = chartData
		const bucketData = getBucketData(data.buckets, interval, filter)
		const maxValue = getMaxSeriesValue(data.buckets)
		return {
			data: getSeriesData(bucketData, MetricsType.ClosedConversation, 0),
			interval,
			maxValue: roundToTheTopTen(maxValue),
		}
	},
)

const getSummaryClosedConversation = createSelector([getChartDataClosedConversation], (chartData) => {
	return chartData.data.value ?? DEFAULT_SUMMARY_VALUE
})

const getIntervalClosedConversation = createSelector([getChartDataClosedConversation], (chartData) => {
	return chartData.interval
})

const getDataServedConversation = createSelector(
	[getChartDataServedConversation, statisticsFilterSelectors.getFilter],
	(chartData, filter) => {
		const { data, interval } = chartData
		const bucketData = getBucketData(data.buckets, interval, filter)
		const maxValue = getMaxSeriesValue(data.buckets)
		return {
			data: getSeriesData(bucketData, MetricsType.ServedConversation, 0),
			interval,
			maxValue: roundToTheTopTen(maxValue),
		}
	},
)

const getIntervalServedConversation = createSelector([getChartDataServedConversation], (chartData) => {
	return chartData.interval
})

const getSummaryServedConversation = createSelector([getChartDataServedConversation], (chartData) => {
	return chartData.data.value ?? DEFAULT_SUMMARY_VALUE
})

const getDataNewContacts = createSelector(
	[getChartDataNewContacts, statisticsFilterSelectors.getFilter],
	(chartData, filter) => {
		const { data, interval } = chartData
		const bucketData = getBucketData(data.buckets, interval, filter)
		const maxValue = getMaxSeriesValue(data.buckets)
		return {
			data: getSeriesData(bucketData, MetricsType.ContactAcquired, 0),
			interval,
			maxValue: roundToTheTopTen(maxValue),
		}
	},
)

const getSummaryNewContacts = createSelector([getChartDataNewContacts], (chartData) => {
	return chartData.data.value ?? DEFAULT_SUMMARY_VALUE
})

const getIntervalNewContacts = createSelector([getChartDataNewContacts], (chartData) => {
	return chartData.interval
})

const getDataResponseTime = createSelector(
	[getChartDataResponseTime, statisticsFilterSelectors.getFilter],
	(chartData, filter) => {
		const { dataFirstResponseTime, dataAvgResponseTime, interval } = chartData
		const bucketDataFirst = getBucketData(dataFirstResponseTime.buckets, interval, filter)
		const bucketDataAvg = getBucketData(dataAvgResponseTime.buckets, interval, filter)

		const maxValueFirst = getMaxSeriesValue(dataFirstResponseTime.buckets)
		const maxValueAvg = getMaxSeriesValue(dataAvgResponseTime.buckets)
		const maxValue = Math.max(maxValueFirst, maxValueAvg)

		const data = [
			...getSeriesData(bucketDataFirst, MetricsType.AvgFirstResponseTime, null),
			...getSeriesData(bucketDataAvg, MetricsType.AvgResponseTime, null),
		]

		return {
			data,
			interval,
			maxValue: roundToTheTopTen(maxValue),
		}
	},
)

const getIntervalResponseTime = createSelector([getChartDataResponseTime], (chartData) => {
	return chartData.interval
})

const getSummaryResponseTime = createSelector([getChartDataResponseTime], (chartData) => {
	return autoFormatSeconds(chartData.dataFirstResponseTime.value)
})

const getSummaryOnlineTime = createSelector([getChartDataOnlineActivity], (chartData) => {
	return autoFormatSeconds(chartData.summaryValue)
})

const getIsAvailableOnlineTime = createSelector([statisticsFilterSelectors.getIsLongTermOnlineTime], (isLongTerm) => {
	return !isLongTerm
})

const getDataOnlineActivity = createSelector([getChartDataOnlineActivity], (chartData) => {
	const { data, interval } = chartData

	return {
		data: data.buckets?.map(transformOnlineActivityBucket),
		interval,
	}
})

const getFormattedAgentsData = createSelector(
	[getAgentsSummary, agentsSelectors.getAppAgentsIncludeRemoved],
	(agentsSummary, appAgents) => {
		const summary: AgentsStatisticsSummary[] = []
		appAgents.forEach((agent) => {
			const summaryItem = { ...DEFAULT_AGENT_METRICS }
			const agentItem: AgentsStatisticsAgent = {
				id: agent.id,
				email: agent.deletedAt ? '' : agent.email,
				name: agent.fullname,
			}
			summary.push({ ...summaryItem, agent: agentItem, key: `${agentItem.id}` })
		})

		return summary
			.map(formatMetricsByAgent(agentsSummary))
			.filter(filterEmptyResults)
			.sort((a, b) => b.new_conversation - a.new_conversation)
	},
)

const getSummaryRating = createSelector([getChartDataRatings], (chartData) => {
	const { value } = chartData.data
	return calcAgentPercentageRating(value)
})

const getSummaryRatingPercentage = createSelector([getChartDataRatingPercentage], (chartData) => {
	return chartData.data.value
})

const getTrendRatingPercentage = createSelector([getChartDataRatingPercentage], (chartData) => {
	return chartData.data.trend ?? null
})

const getIntervalRating = createSelector([getChartDataRatings], (chartData) => {
	return chartData.interval
})

const getDataDetailRating = createSelector([getChartDataRatings], (chartData) => {
	return chartData.ratings
})

const makeGetIsFetchingByType = (type: Exclude<ChartType, ChartType.Tags>) => {
	return createSelector([getChartsData], (chartsData): boolean => {
		return chartsData[type].isFetching
	})
}

const getChartDataDetailRatings = createSelector(
	[getChartDataRatings, statisticsFilterSelectors.getFilter],
	(chartData, filter) => {
		const { ratings, interval } = chartData
		const resultData: { [key: string]: RatingsChartData } = {}
		Object.entries(ratings).forEach(([ratingType, data]: [string, MetricsData]) => {
			const { buckets } = data
			if (buckets) {
				const bucketData = getBucketData(buckets, interval, filter)

				bucketData.forEach((bucket) => {
					const prepareBucket = { ...bucket, key: bucket.longName }
					const { key, count } = prepareBucket
					let itemByKey
					itemByKey = resultData[key] ? { ...resultData[key] } : { ...DEFAULT_RATING, key }

					const { values } = itemByKey
					switch (ratingType) {
						case Ratings.Happy: {
							itemByKey = { ...itemByKey, values: { ...values, [Ratings.Happy]: count } }
							break
						}
						case Ratings.Ok: {
							itemByKey = { ...itemByKey, values: { ...values, [Ratings.Ok]: count } }
							break
						}
						case Ratings.Bad: {
							itemByKey = { ...itemByKey, values: { ...values, [Ratings.Bad]: count } }
							break
						}
					}
					resultData[key] = { ...itemByKey }
				})
			}
		})

		return Object.values(resultData).map((item: RatingsChartData) => addPercentagesToResult(item))
	},
)

const { actions, reducer } = statisticsSlice
export const { selectStatistic, setIsDataValid } = actions
export const statisticsSelectors = {
	getSelectedStatistic,
	getIsInitialized,
	getSummaryRating,
	getSummaryRatingPercentage,
	getSummaryNewConversation,
	getSummaryResponseTime,
	getSummaryNewContacts,
	getDataNewContacts,
	getDataNewConversation,
	getDataClosedConversation,
	getSummaryClosedConversation,
	getDataServedConversation,
	getSummaryServedConversation,
	getDataResponseTime,
	getIsDataValid,
	isFetchingAgentsStatistics,
	getFormattedAgentsData,
	getDataDetailRating,
	getChartDataDetailRatings,
	makeGetIsFetchingByType,
	getIntervalRating,
	getIntervalResponseTime,
	getIntervalNewConversation,
	getIntervalClosedConversation,
	getIntervalServedConversation,
	getIntervalNewContacts,
	getDataOnlineActivity,
	getSummaryOnlineTime,
	getIsAvailableOnlineTime,
	getTrendNewConversation,
	getTrendRatingPercentage,
}
export default reducer
