import { SurveyApi, SurveyKey } from '@smartsupp/core-client-api'

export { SurveyKey } from '@smartsupp/core-client-api'

export type SurveyData = SurveyApi.AnswerBody | null

export enum SurveyValidationType {
	Valid = 'valid',
	MissingOption = 'missing-option',
	MissingText = 'missing-text',
	MissingRating = 'missing-rating',
	TextTooLong = 'text-too-long',
}

export type UsedSurveyKey = Exclude<SurveyKey, typeof SurveyKey.ConversationFlow>

export enum DemoFormOptions {
	Groups = 'dash-groups',
	VisitorDetail = 'dash-visitor-detail',
	BillingChatbots = 'dash-checkout-chatbot-conversations',
	ContactProperties = 'dash-contact-properties',
	ChatbotBuilder = 'dash-chatbot-builder',
	BillingAgents = 'dash-checkout-agents',
	BillingLivechatConversations = 'dash-checkout-livechat-conversations',
	BillingAiConversations = 'dash-checkout-ai-conversations',
	AiSources = 'dash-ai-sources',
	AiActiveChatbots = 'dash-ai-active-chatbots',
	AiTotalChatbots = 'dash-ai-total-chatbots',
	AiPreviewMessages = 'dash-ai-preview-messages',
	AiTraining = 'dash-ai-training',
}

export enum DemoButtonOptions {
	GroupsBanner = 'groupsBanner',
	GroupsEmpty = 'groupsEmpty',
	GroupsUpsell = 'groupsUpsell',
	PropertiesBanner = 'propertiesBanner',
	PropertiesEmpty = 'propertiesEmpty',
	BannerInfoPanel = 'bannerInfoPanel',
	ChatbotBuilder = 'chatbotBuilder',
	AiActiveChatbots = 'aiActiveChatbots',
	AiTotalChatbots = 'aiTotalChatbots',
	AiSources = 'aiSources',
	AiPreviewMessages = 'aiPreviewMessages',
	AiTraining = 'aiTraining',
	AiConversations = 'aiConversations',
}

export type DemoFormData = {
	name: string
	surname: string
	phoneNumber: string
	formValue: string
	source: DemoFormOptions
	lang: string
	question: string
}
