import { PackagePricing, PackagesFeature, PackagesFeaturesTableV2 } from 'shared/models/Package'
import { ConfigurationService } from 'shared/services'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'packages'

const getPackages = () => {
	return apiClient.get<PackagePricing[]>(`${API_RESOURCE}`)
}

const getPackagesFeatureSet = () => {
	const { lang } = ConfigurationService.getData()
	return apiClient.get<PackagesFeature[]>(`${API_RESOURCE}/features-table?lang=${lang}`)
}

const getPackagesFeatureSetV2 = () => {
	const { lang } = ConfigurationService.getData()
	return apiClient.get<PackagesFeaturesTableV2>(`${API_RESOURCE}/features-table-ii?lang=${lang}`)
}

export const packagesApi = { getPackages, getPackagesFeatureSet, getPackagesFeatureSetV2 }
