import {
	CheckoutStep,
	CheckoutStepPlaceholder,
	FlashDealNewSubscriptionSteps,
	FlashDealUpdateSubscriptionSteps,
	NewSubscriptionSteps,
	ShopifySubscriptionSteps,
	UpdateSubscriptionSteps,
} from './types'

export const MIN_CHECKOUT_AGENTS = 0
export const MAX_CHECKOUT_AGENTS = 100
export const DEFAULT_UNDEFINED_CURRENCY = 'eur'
export const DEFAULT_UNDEFINED_PRICE = 100

const stepPackages = {
	number: 0,
	name: CheckoutStepPlaceholder.Packages,
	title: 'billing.packages.choosePackage',
}

const stepConfiguration = {
	number: 1,
	name: CheckoutStep.Configuration,
	title: 'billing.configuration',
}

const stepBillingInfo = {
	number: 2,
	name: CheckoutStep.BillingInfo,
	title: 'billing.billingInfo',
}

export const newSubscriptionSteps: NewSubscriptionSteps = {
	[CheckoutStepPlaceholder.Packages]: stepPackages,
	[CheckoutStep.Configuration]: stepConfiguration,
	[CheckoutStep.BillingInfo]: stepBillingInfo,
	[CheckoutStep.Payment]: {
		number: 3,
		name: CheckoutStep.Payment,
		title: 'billing.payment',
	},
}

export const updateSubscriptionSteps: UpdateSubscriptionSteps = {
	[CheckoutStepPlaceholder.Packages]: stepPackages,
	[CheckoutStep.Configuration]: stepConfiguration,
	[CheckoutStep.PaymentUpdate]: {
		number: 2,
		name: CheckoutStep.PaymentUpdate,
		title: 'billing.payment',
	},
}

export const shopifySubscriptionSteps: ShopifySubscriptionSteps = {
	[CheckoutStepPlaceholder.Packages]: stepPackages,
	[CheckoutStep.Shopify]: {
		number: 1,
		name: CheckoutStep.Shopify,
		title: 'billing.payment',
	},
}

export const flashDealNewSubscriptionSteps: FlashDealNewSubscriptionSteps = {
	[CheckoutStep.Configuration]: {
		number: 1,
		name: CheckoutStep.Configuration,
		title: 'billing.limitedOffer',
	},
	[CheckoutStep.BillingInfo]: stepBillingInfo,
	[CheckoutStep.Payment]: {
		number: 3,
		name: CheckoutStep.Payment,
		title: 'billing.payment',
	},
}

export const flashDealUpdateSubscriptionSteps: FlashDealUpdateSubscriptionSteps = {
	[CheckoutStep.Configuration]: {
		number: 1,
		name: CheckoutStep.Configuration,
		title: 'billing.limitedOffer',
	},
	[CheckoutStep.PaymentUpdate]: {
		number: 2,
		name: CheckoutStep.Payment,
		title: 'billing.payment',
	},
}

export enum CalculationTrigger {
	Default,
	SetAgents,
	SetChatbot,
	SetLivechatConversation,
	SetAiConversation,
}
