import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FlashDeal, PackageIntervalAvailable } from 'models'
import { ApiError } from 'shared/types'
import { AppThunkAction, DashboardState } from 'types'
import { flashMessage } from 'shared/utils'
import { routes } from 'configuration/routes'
import { ERROR_FLASH_DEAL_EXPIRED, ERROR_NOT_ALLOWED, ERROR_NOT_FOUND } from 'constants/errors'
import { redirectTo } from 'utils'
import { openFlashDealCheckout } from 'modules/checkout'
import { packageSelectors } from 'modules/package'

import { flashDealApi } from './api'

const errorsArray = new Set([ERROR_NOT_FOUND, ERROR_NOT_ALLOWED, ERROR_FLASH_DEAL_EXPIRED])

export const fetchFlashDealThunk = createAsyncThunk<FlashDeal, string, { rejectValue: ApiError }>(
	'flashDeal/FETCH',
	async (token: string, { rejectWithValue }) => {
		try {
			return await flashDealApi.fetchFlashDeal(token)
		} catch (error) {
			return rejectWithValue(error as ApiError)
		}
	},
)

const fetchFlashDealDemoThunk = createAsyncThunk('flashDeal/FETCH_DEMO', (token: string) => {
	return flashDealApi.fetchFlashDealDemo(token)
})

export const fetchFlashDealDemo =
	(token: string): AppThunkAction =>
	async (dispatch) => {
		const actionResult = await dispatch(fetchFlashDealDemoThunk(token))
		if (fetchFlashDealDemoThunk.fulfilled.match(actionResult)) {
			flashMessage.success('flashDeal.result.success')
			redirectTo(routes.conversations.path)
		} else {
			flashMessage.error('flashDeal.result.error')
		}
	}

export const showFlashDealError =
	(error: string): AppThunkAction =>
	(dispatch) => {
		dispatch(setError(error))
		dispatch(clearFlashDealToken())
		redirectTo(routes.flashDealError.path)
	}

export const fetchFlashDeal =
	(token: string): AppThunkAction =>
	async (dispatch, getState) => {
		const packageInfo = packageSelectors.getPackageInfo(getState())
		const actionResult = await dispatch(fetchFlashDealThunk(token))
		if (fetchFlashDealThunk.fulfilled.match(actionResult)) {
			const { demoDays, agents, interval } = actionResult.payload
			const packageName = actionResult.payload.package
			const maxAgents = Math.max(packageInfo?.agents ?? 0, agents)
			if (demoDays > 0) {
				dispatch(fetchFlashDealDemo(token))
			} else {
				dispatch(openFlashDealCheckout(packageName, maxAgents, interval as PackageIntervalAvailable))
			}
		} else {
			const error = actionResult.payload ? actionResult.payload.code : ERROR_NOT_FOUND
			dispatch(setError(error))
		}
	}

export const initialState = {
	token: null as null | string,
	error: null as null | string,
	isFetching: false,
}

export type FlashDealRootState = Pick<DashboardState, 'flashDeal'>
export type FlashDealState = typeof initialState

const flashDealSlice = createSlice({
	name: 'flashDeal',
	initialState,
	reducers: {
		clearFlashDealToken: (state) => {
			state.token = null
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchFlashDealThunk.pending, (state) => {
				state.isFetching = true
				state.error = null
			})
			.addCase(fetchFlashDealThunk.fulfilled, (state, { meta }) => {
				state.isFetching = false
				state.error = null
				state.token = meta.arg
			})
			.addCase(fetchFlashDealThunk.rejected, (state) => {
				state.isFetching = false
			})
	},
})

const { reducer, actions } = flashDealSlice
export default reducer
export const { clearFlashDealToken, setError } = actions

const getToken = (state: FlashDealRootState) => state.flashDeal.token
const getIsFetching = (state: FlashDealRootState) => state.flashDeal.isFetching
const getError = (state: FlashDealRootState) => state.flashDeal.error

const getIsFlashDealAvailable = createSelector([getToken], (token): boolean => {
	return !!token
})

const getErrorType = createSelector([getError], (error): string | null => {
	if (!error) return null
	if (errorsArray.has(error)) return error
	return ERROR_NOT_FOUND
})

export const flashDealSelectors = {
	getToken,
	getIsFetching,
	getErrorType,
	getIsFlashDealAvailable,
}
