import { FlashDeal } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'flash-deal'

const fetchFlashDeal = (token: string) => {
	return apiClient.get<FlashDeal>(`${API_RESOURCE}/${token}`)
}

const fetchFlashDealDemo = (token: string) => {
	return apiClient.post(`${API_RESOURCE}/${token}/demo`)
}

export const flashDealApi = { fetchFlashDeal, fetchFlashDealDemo }
