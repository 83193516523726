import { CreateSourceBody, PatchSourceBody, Source, SourceCheckResult, SourceContent } from 'shared/models/Source'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'mira/sources'

const searchSources = () => {
	return apiClient.post<Source[]>(`${API_RESOURCE}/search`)
}

const createSource = (data: CreateSourceBody) => {
	return apiClient.post<Source>(`${API_RESOURCE}`, data)
}

const editSource = (id: Source['id'], changes: PatchSourceBody) => {
	return apiClient.patch<Source>(`${API_RESOURCE}/${id}`, changes)
}

const deleteSource = (id: Source['id']) => {
	return apiClient.delete(`${API_RESOURCE}/${id}`)
}

const refreshSource = (id: Source['id']) => {
	return apiClient.post<Source>(`${API_RESOURCE}/${id}/refresh`)
}

const getFiles = (id: Source['id']) => {
	return apiClient.get<SourceContent>(`${API_RESOURCE}/${id}/content`)
}

const checkSource = (parser: Source['parser']) => {
	return apiClient.post<SourceCheckResult>(`${API_RESOURCE}/check`, { parser })
}

export const sourcesApi = {
	searchSources,
	createSource,
	editSource,
	deleteSource,
	refreshSource,
	checkSource,
	getFiles,
}
