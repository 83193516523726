import {
	AGENTS_PANEL_HEIGHT,
	CHAT_LIST_ITEM_HEIGHT,
	HEADER_HEIGHT,
	SIDEBAR_WIDTH,
	UPSELL_BAR_HEIGHT,
} from '../../constants'

export const sizes = {
	agentsPanelHeight: `${AGENTS_PANEL_HEIGHT}px`,
	conversationsCountExportHeight: '48px',
	upsellBarHeight: `${UPSELL_BAR_HEIGHT}px`,
	chatFilterHeight: '48px',
	chatListItemHeight: `${CHAT_LIST_ITEM_HEIGHT}px`,
	headerHeight: `${HEADER_HEIGHT}px`,
	sidebarWidth: `${SIDEBAR_WIDTH}px`,
}
