import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'

import { ChatStatus, PackageFeature, PackageName } from 'models'
import { TranslationParamValue } from 'shared/types'
import { AppThunkAction, DashboardState } from 'types'
import { isFeatureEnabled } from 'shared/services'
import { getChatId } from 'selectors/commonSelectors'
import { accountSelectors } from 'modules/account'
import { agentsSelectors } from 'modules/agents'
import { autoMessagesSelectors } from 'modules/autoMessages'
import { chatbotDetailSelectors } from 'modules/chatbotDetail'
import { chatbotSelectors } from 'modules/chatbots'
import { chatsSelectors } from 'modules/chats'
import { invitationsSelectors } from 'modules/invitations'
import { packageSelectors } from 'modules/package'
import { packagesSelectors } from 'modules/packages'
import { paymentMethodSelectors } from 'modules/paymentMethod'
import { shortcutsSelectors } from 'modules/shortcuts'
import { stripeSharedSelectors } from 'modules/stripe'
import { triggersSelectors } from 'modules/triggers'
import { usageSelectors } from 'modules/usage'
import { userSelectors } from 'modules/user'

import { UpsellType } from './constants'
import {
	AllowedUpsellModalType,
	UpsellContext,
	UpsellContextChatbot,
	UpsellContextChatbotActions,
	UpsellContextGroups,
} from './types'

export type UpsellRootState = Pick<DashboardState, 'upsell'>
type UpsellMetadata = Record<string, TranslationParamValue>

const initialState = {
	isActive: false,
	upsellType: null as null | AllowedUpsellModalType,
	upsellMetadata: null as null | UpsellMetadata,
	context: null as null | UpsellContext,
}

const shouldShowUpsell =
	(
		upsellType: AllowedUpsellModalType,
		isConditionFulfilled: boolean,
		next?: () => void,
		context = null as null | UpsellContext,
		upsellMetadata = null as null | UpsellMetadata,
	): AppThunkAction =>
	(dispatch) => {
		if (isConditionFulfilled) {
			dispatch(actions.showUpsell({ type: upsellType, context, upsellMetadata }))
		} else if (next) {
			next()
		}
	}

// UPSELLS
export const shouldShowShortcutsTeamUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledTeamShortcutsFeature(getState())
		dispatch(shouldShowUpsell(UpsellType.ShortcutsTeam, isConditionFulfilled, next))
	}

export const shouldShowShortcutsLimitUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !shortcutsSelectors.canShortcutBeCreated(getState())
		dispatch(shouldShowUpsell(UpsellType.ShortcutsLimit, isConditionFulfilled, next))
	}

export const shouldShowBanContactUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledBanContact(getState())
		dispatch(shouldShowUpsell(UpsellType.BlockVisitor, isConditionFulfilled, next))
	}

export const shouldShowIpAddressUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledIpTracking(getState())
		dispatch(shouldShowUpsell(UpsellType.ShowIP, isConditionFulfilled, next))
	}

export const shouldShowBlockIpAddressUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledBlockingIpAddress(getState())
		dispatch(shouldShowUpsell(UpsellType.BlockIpAddress, isConditionFulfilled, next))
	}

export const shouldShowChatTranscriptUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledChatTranscript(getState())
		dispatch(shouldShowUpsell(UpsellType.SendChatTranscript, isConditionFulfilled, next))
	}

export const shouldShowAssignAgentUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledAssignAgent(getState())
		dispatch(shouldShowUpsell(UpsellType.AssignAgent, isConditionFulfilled, next))
	}

export const shouldShowVisitorListFilterUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledVisitorListFilter(getState())
		dispatch(shouldShowUpsell(UpsellType.VisitorListFilter, isConditionFulfilled, next))
	}

export const shouldShowChatbotsUpsell =
	(next: () => void, context: UpsellContextChatbot): AppThunkAction =>
	(dispatch, getState) => {
		const upsellType = getChatbotUpsellType(getState())
		const isConditionFulfilled = !!upsellType
		dispatch(shouldShowUpsell(upsellType ?? UpsellType.Chatbots, isConditionFulfilled, next, context))
	}

export const shouldShowAutoMessagesUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const upsellType = getAutoMessageUpsellType(state)
		const maxActiveAutoMessageCount = autoMessagesSelectors.getMaxActiveAutoMessagesCount(state)
		const upsellMetadata =
			upsellType === UpsellType.AutoMessagesExtra
				? {
						count: `${maxActiveAutoMessageCount}`,
				  }
				: undefined
		const isConditionFulfilled = !!upsellType
		dispatch(shouldShowUpsell(upsellType ?? UpsellType.AutoMessages, isConditionFulfilled, next, null, upsellMetadata))
	}

export const shouldShowTriggersUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !triggersSelectors.canActivateTrigger(getState())
		dispatch(shouldShowUpsell(UpsellType.Triggers, isConditionFulfilled, next))
	}

export const shouldShowAgentsAddUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !agentsSelectors.canActivateAgent(getState())
		const upsellType = getActivateAgentUpsellType(getState())
		dispatch(shouldShowUpsell(upsellType ?? UpsellType.AgentsAdd, isConditionFulfilled, next))
	}

export const shouldShowAgentsInvitationsLimitReached =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const invitationSlots = invitationsSelectors.getInvitationSlots(getState())
		const isConditionFulfilled = !!invitationSlots && invitationSlots.activeSlots >= invitationSlots.maxSlots
		const upsellType = getInvitationAgentUpsellType(getState())
		dispatch(shouldShowUpsell(upsellType ?? UpsellType.AgentsAdd, isConditionFulfilled, next))
	}

export const shouldShowExportChatsUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledExportChatsFeature(getState())
		dispatch(shouldShowUpsell(UpsellType.ExportChats, isConditionFulfilled, next))
	}

export const shouldShowGroupsUpsell =
	(context: UpsellContextGroups, next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledGroupsFeature(getState())
		dispatch(shouldShowUpsell(UpsellType.Groups, isConditionFulfilled, next, context))
	}

export const shouldShowOfficeHoursUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledOfficeHoursFeature(getState())
		dispatch(shouldShowUpsell(UpsellType.OfficeHours, isConditionFulfilled, next))
	}

export const shouldShowNotificationsMissedConversationUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledNotificationsMissedConversation(getState())
		dispatch(shouldShowUpsell(UpsellType.NotificationsMissedConversation, isConditionFulfilled, next))
	}

export const shouldShowWidgetOrientationUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledCustomChatBox(getState())
		dispatch(shouldShowUpsell(UpsellType.WidgetCustomization, isConditionFulfilled, next))
	}

export const shouldShowCustomerSatisfactionRatingUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledCustomChatBox(getState())
		dispatch(shouldShowUpsell(UpsellType.WidgetCustomization, isConditionFulfilled, next))
	}

export const shouldShowAuthFormUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledCustomChatBox(getState())
		dispatch(shouldShowUpsell(UpsellType.WidgetPreChatForm, isConditionFulfilled, next))
	}

export const shouldShowAuthFormPhoneControlUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledCustomChatBox(getState())
		dispatch(shouldShowUpsell(UpsellType.PhoneNumber, isConditionFulfilled, next))
	}

export const shouldShowHideWidgetUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledCustomChatBox(getState())
		dispatch(shouldShowUpsell(UpsellType.WidgetHide, isConditionFulfilled, next))
	}

export const shouldShowPrivacyNoticeUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledCustomChatBox(getState())
		dispatch(shouldShowUpsell(UpsellType.WidgetCustomization, isConditionFulfilled, next))
	}

export const shouldShowVisitorEngagementUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledVisitorEngagement(getState())
		dispatch(shouldShowUpsell(UpsellType.VisitorEngagement, isConditionFulfilled, next))
	}

export const shouldShowGoogleAnalyticsUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledGoogleAnalyticsFeature(getState())
		dispatch(shouldShowUpsell(UpsellType.GoogleAnalytics, isConditionFulfilled, next))
	}

export const shouldShowChatbotActionsUpsell =
	(next?: () => void): AppThunkAction =>
	(dispatch, getState) => {
		const isConditionFulfilled = !hasEnabledChatbotActions(getState())
		const isSelectedAutoMessage = chatbotDetailSelectors.getIsSelectedAutoMessage(getState())
		const upsellContext = isSelectedAutoMessage
			? UpsellContextChatbotActions.AutoMessageBuilder
			: UpsellContextChatbotActions.ChatbotBuilder

		dispatch(shouldShowUpsell(UpsellType.ChatbotActions, isConditionFulfilled, next, upsellContext))
	}

const upsellSlice = createSlice({
	name: 'upsell',
	initialState,
	reducers: {
		showUpsell: (
			state,
			{
				payload,
			}: PayloadAction<{
				type: AllowedUpsellModalType
				context: UpsellContext | null
				upsellMetadata: UpsellMetadata | null
			}>,
		) => {
			state.isActive = true
			state.upsellType = payload.type
			state.context = payload.context
			state.upsellMetadata = payload.upsellMetadata
		},
		hideUpsell: (state) => {
			state.isActive = false
			state.upsellType = null
			state.context = null
			state.upsellMetadata = null
		},
	},
})

const { actions, reducer } = upsellSlice
export const { hideUpsell, showUpsell } = actions
export default reducer

// SELECTORS
const isUpsellActive = (state: UpsellRootState) => state.upsell.isActive
const getUpsellType = (state: UpsellRootState) => state.upsell.upsellType
const getUpsellMetadata = (state: UpsellRootState) => state.upsell.upsellMetadata
const getUpsellContext = (state: UpsellRootState) => state.upsell.context

const isUpsellBarVisible = createSelector(
	[
		packageSelectors.getPackageInfo,
		userSelectors.getActiveUser,
		paymentMethodSelectors.getCardExpiryNotifications,
		usageSelectors.getMonthlyServedLimits,
		usageSelectors.getChatbotConversationsLimits,
		usageSelectors.getAiConversationsLimits,
		accountSelectors.getIsChatCodeInstalled,
		stripeSharedSelectors.getIsSubscriptionPastDue,
	],
	(
		packageInfo,
		user,
		cardExpiryNotifications,
		livechatConversationsLimits,
		chatbotConversationsLimit,
		aiConversationsLimit,
		chatCodeInstalled,
		isStripeSubscriptionPastDue,
	) => {
		if (!packageInfo || !user) return false
		const { notifications } = packageInfo
		const { shouldShowExpiredWarning, shouldShowExpiringWarning } = cardExpiryNotifications

		if (user.isAdmin) {
			return (
				notifications.hasUnpaidFirstInvoice ||
				notifications.hasUnpaidGeneratedInvoice ||
				notifications.isPackageExpiring ||
				notifications.isPackagePastDue ||
				isStripeSubscriptionPastDue ||
				notifications.isTrialExpiring ||
				(user.isAdmin && notifications.isDowngradedToFree) ||
				shouldShowExpiredWarning ||
				shouldShowExpiringWarning ||
				(livechatConversationsLimits.isOverThreshold90 && livechatConversationsLimits.isAllowedNotificationInMonth) ||
				livechatConversationsLimits.isOverThreshold95 ||
				livechatConversationsLimits.isOverLimit ||
				chatbotConversationsLimit.isOverLimit ||
				aiConversationsLimit.isOverLimit ||
				!chatCodeInstalled
			)
		}
		return (
			(livechatConversationsLimits.isOverThreshold90 && livechatConversationsLimits.isAllowedNotificationInMonth) ||
			livechatConversationsLimits.isOverThreshold95 ||
			livechatConversationsLimits.isOverLimit
		)
	},
)

const isUpsellButtonVisible = createSelector(
	[
		packageSelectors.getPackageInfo,
		userSelectors.getActiveUser,
		paymentMethodSelectors.getCardExpiryNotifications,
		usageSelectors.getMonthlyServedLimits,
		usageSelectors.getChatbotConversationsLimits,
		accountSelectors.getIsChatCodeInstalled,
		stripeSharedSelectors.getHasUnpaidInvoice,
	],
	(
		packageInfo,
		user,
		cardExpiryNotifications,
		monthlyServedLimits,
		chatbotConversationsLimit,
		chatCodeInstalled,
		hasUnpaidInvoice,
	) => {
		if (!packageInfo || !user || !user.isAdmin) return false
		const { notifications } = packageInfo
		const { shouldShowExpiredWarning, shouldShowExpiringWarning } = cardExpiryNotifications
		return (
			notifications.hasUnpaidFirstInvoice ||
			notifications.hasUnpaidGeneratedInvoice ||
			notifications.isPackageExpiring ||
			notifications.isDowngradedToFree ||
			notifications.isTrialExpiring ||
			shouldShowExpiredWarning ||
			shouldShowExpiringWarning ||
			monthlyServedLimits.isOverThreshold90 ||
			chatbotConversationsLimit.isOverLimit ||
			hasUnpaidInvoice ||
			!chatCodeInstalled
		)
	},
)

const makeGetUpsellTypeChatHistoryDetail = () =>
	createSelector(
		[packageSelectors.getPackageInfo, chatsSelectors.getSelectedChat, chatsSelectors.getChatsMap, getChatId],
		(packageInfo, selectedChat, chats, chatId): UpsellType | null => {
			if (!packageInfo || !chatId) return null

			const chat = chats[chatId] ?? selectedChat
			if (!chat) return null

			const chatDateFinishedAt = moment(chat.finishedAt)

			const now = moment.utc()
			const days14Ago = moment(now).subtract(14, 'days')
			const months12Ago = moment(now).subtract(12, 'months')
			const months36Ago = moment(now).subtract(36, 'months')
			const isOlderThan14days = moment(days14Ago).isAfter(chatDateFinishedAt)
			const isOlderThan12Months = moment(months12Ago).isAfter(chatDateFinishedAt)
			const isOlderThan36Months = moment(months36Ago).isAfter(chatDateFinishedAt)

			if (chat.status !== ChatStatus.Closed) return null

			switch (packageInfo.name) {
				case PackageName.Free:
				case PackageName.Mini: {
					if (isOlderThan14days && !isOlderThan12Months) return UpsellType.ChatHistoryDetailMonths12
					if (isOlderThan12Months && !isOlderThan36Months) return UpsellType.ChatHistoryDetailMonths36
					if (isOlderThan36Months) return UpsellType.ChatHistoryDetailUnlimited
					break
				}
				case PackageName.Basic: {
					if (isOlderThan12Months && !isOlderThan36Months) return UpsellType.ChatHistoryDetailMonths36
					if (isOlderThan36Months) return UpsellType.ChatHistoryDetailUnlimited
					break
				}
				case PackageName.Pro:
				case PackageName.Trial: {
					if (isOlderThan36Months) return UpsellType.ChatHistoryDetailUnlimited
					break
				}
				default: {
					return null
				}
			}

			return null
		},
	)

const hasEnabledAssignAgent = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return !packageInfo.isFree && !packageInfo.isMini
})

const hasEnabledStatisticsFeature = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return isFeatureEnabled(packageInfo.features, PackageFeature.Statistics)
})

const hasEnabledBasicStatisticsFeature = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return isFeatureEnabled(packageInfo.features, PackageFeature.StatisticsBasic)
})

const hasEnabledAddons = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return !packageInfo.isFree && !packageInfo.isMini
})

const hasEnabledVisitorListFilter = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return !packageInfo.isFree && !packageInfo.isMini
})

const hasEnabledBanContact = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return !packageInfo.isFree && !packageInfo.isMini
})

const hasEnabledChatbotConversations = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return !packageInfo.isFree && !packageInfo.isMini
})

const hasEnabledContactsToCrm = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return !packageInfo.isFree && !packageInfo.isMini
})

const hasEnabledTeamShortcutsFeature = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return isFeatureEnabled(packageInfo.features, PackageFeature.ShortcutsTeam)
})

const hasEnabledExportChatsFeature = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return packageInfo.isPro || packageInfo.isTrial
})

const hasEnabledGroupsFeature = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return packageInfo.features.hasEnabledGroups
})

const hasEnabledOfficeHoursFeature = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return isFeatureEnabled(packageInfo.features, PackageFeature.OfficeHours)
})

const hasEnabledGoogleAnalyticsFeature = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return packageInfo.features.hasEnabledGoogleAnalytics
})

const hasEnabledNotificationsMissedConversation = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return !packageInfo.isFree && !packageInfo.isMini
})

const hasEnabledIpTracking = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return !packageInfo.isFree && !packageInfo.isMini
})

const hasEnabledBlockingIpAddress = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return !packageInfo.isFree && !packageInfo.isMini
})

export const hasEnabledChatTranscript = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	// Info: For Package Trial is transcript unavailable due to Spam issue
	return !(packageInfo.isFree || packageInfo.isMini || packageInfo.isTrial)
})

const hasEnabledFacebookConnect = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return isFeatureEnabled(packageInfo.features, PackageFeature.Facebook)
})

const hasEnabledExportContacts = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return isFeatureEnabled(packageInfo.features, PackageFeature.ExportContacts)
})

const hasEnabledContactProperties = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return isFeatureEnabled(packageInfo.features, PackageFeature.ContactProperties)
})

const hasEnabledCustomChatBox = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return isFeatureEnabled(packageInfo.features, PackageFeature.CustomWidget)
})

const hasEnabledVisitorEngagement = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return isFeatureEnabled(packageInfo.features, PackageFeature.VisitorEngagement)
})

const hasEnabledTags = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return isFeatureEnabled(packageInfo.features, PackageFeature.Tags)
})

const hasEnabledAccountReview = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return isFeatureEnabled(packageInfo.features, PackageFeature.AccountReview)
})

const hasEnabledChatbotActions = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return isFeatureEnabled(packageInfo.features, PackageFeature.ChatbotActions)
})

const hasEnabledChatbotLeadgen = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	return isFeatureEnabled(packageInfo.features, PackageFeature.ChatbotLeadgen)
})

const getChatbotUpsellType = createSelector(
	[packageSelectors.getPackageInfo, chatbotSelectors.getCanActivateChatbot],
	(packageInfo, canActivateChatbot): typeof UpsellType.ChatbotsExtra | typeof UpsellType.Chatbots | null => {
		if (!packageInfo || canActivateChatbot) return null

		// Show extra upsell for Trial or Pro package
		const shouldShowExtraUpsell = packageInfo?.name === PackageName.Trial || packageInfo?.name === PackageName.Pro
		return shouldShowExtraUpsell ? UpsellType.ChatbotsExtra : UpsellType.Chatbots
	},
)

const getAutoMessageUpsellType = createSelector(
	[packageSelectors.getPackageInfo, autoMessagesSelectors.getCanActivateAutoMessage],
	(
		packageInfo,
		canActivateAutoMessage,
	): typeof UpsellType.AutoMessagesExtra | typeof UpsellType.AutoMessages | null => {
		if (!packageInfo || canActivateAutoMessage) return null

		// Show extra upsell for Trial or Pro package
		const shouldShowExtraUpsell = packageInfo?.name === PackageName.Trial || packageInfo?.name === PackageName.Pro
		return shouldShowExtraUpsell ? UpsellType.AutoMessagesExtra : UpsellType.AutoMessages
	},
)

const getActivateAgentUpsellType = createSelector(
	[packageSelectors.getPackageInfo, packagesSelectors.getPackages, agentsSelectors.getActiveAgentsCount],
	(packageInfo, packages, activeAgents) => {
		if (!packageInfo || !packages) return null

		const { name } = packageInfo
		if (!packages[name]) return UpsellType.AgentsAdd
		const packageMaxAgents = packages[name].maxAgentsCount

		const shouldShowUltimateUpsell = name === PackageName.Pro && packageMaxAgents && activeAgents >= packageMaxAgents

		return shouldShowUltimateUpsell ? UpsellType.AgentsAddUltimate : UpsellType.AgentsAdd
	},
)

const getInvitationAgentUpsellType = createSelector(
	[packageSelectors.getPackageInfo, packagesSelectors.getPackages, invitationsSelectors.getInvitationSlots],
	(packageInfo, packages, invitations) => {
		if (!packageInfo || !packages || !invitations) return null

		const { name } = packageInfo
		if (!packages[name]) return UpsellType.AgentsAdd
		const packageMaxAgents = packages[name].maxAgentsCount

		const shouldShowUltimateUpsell =
			name === PackageName.Pro && packageMaxAgents && invitations.activeSlots >= packageMaxAgents

		return shouldShowUltimateUpsell ? UpsellType.AgentsAddUltimate : UpsellType.AgentsAdd
	},
)

const getAiReplyAssistUpsellType = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	return packageInfo?.isBasic ? UpsellType.AiReplyAssistToPro : UpsellType.AiReplyAssistToBasic
})

export const upsellSelectors = {
	isUpsellActive,
	getUpsellType,
	isUpsellBarVisible,
	isUpsellButtonVisible,
	makeGetUpsellTypeChatHistoryDetail,
	hasEnabledAssignAgent,
	hasEnabledStatisticsFeature,
	hasEnabledBasicStatisticsFeature,
	hasEnabledBanContact,
	hasEnabledExportChatsFeature,
	hasEnabledVisitorListFilter,
	hasEnabledTeamShortcutsFeature,
	hasEnabledFacebookConnect,
	hasEnabledGroupsFeature,
	hasEnabledNotificationsMissedConversation,
	hasEnabledAddons,
	hasEnabledCustomChatBox,
	hasEnabledVisitorEngagement,
	hasEnabledGoogleAnalyticsFeature,
	hasEnabledOfficeHoursFeature,
	hasEnabledTags,
	hasEnabledChatTranscript,
	getChatbotUpsellType,
	getAutoMessageUpsellType,
	hasEnabledExportContacts,
	hasEnabledAccountReview,
	hasEnabledChatbotActions,
	hasEnabledChatbotLeadgen,
	getUpsellMetadata,
	hasEnabledContactProperties,
	getAiReplyAssistUpsellType,
	getUpsellContext,
	hasEnabledIpTracking,
	hasEnabledBlockingIpAddress,
	hasEnabledChatbotConversations,
	hasEnabledContactsToCrm,
}
