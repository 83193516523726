import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { PackageName, PackagesType } from 'models'
import { createBillingPackagesLink, createCheckoutLink, routes } from 'configuration/routes'
import { QueryParams } from 'utils'
import { ConfigurationItemType } from 'modules/checkout'
import { packageSelectors } from 'modules/package'

export type LocalLink = { path: string; params: QueryParams }

export type BillingLinkType = keyof typeof linkMap

const linkMap = {
	profile: {
		getLocal: () => ({
			path: routes.billingProfile.path,
			params: {},
		}),
	},
	profileRenewal: {
		getLocal: () => ({
			path: routes.billingProfile.path,
			params: { modal: 'renewal' },
		}),
	},
	profilePaymentMethod: {
		getLocal: () => ({
			path: routes.billingProfile.path,
			params: { modal: 'paymentMethod' },
		}),
	},
	packages: {
		getLocal: (packageName: PackageName) => ({
			path: createBillingPackagesLink(packageName === PackageName.Mini ? PackagesType.Ai : PackagesType.Livechat),
			params: {},
		}),
	},
	history: {
		getLocal: () => ({
			path: routes.billingProfileInvoices.path,
			params: {},
		}),
	},
	checkout: {
		getLocal: (packageName: PackageName, configurationType: BillingLinkConfigProps['configurationType']) => {
			const params = configurationType ? { from: configurationType } : []
			return { path: createCheckoutLink(packageName), params }
		},
	},
	unpaidInvoice: {
		getLocal: () => ({
			path: routes.unpaidInvoice.path,
			params: {},
		}),
	},
}

type BillingLinkConfigProps = {
	to: BillingLinkType
	selectedPackage?: PackageName
	configurationType?: ConfigurationItemType | null
}

export const useBillingLink = ({ to, selectedPackage, configurationType = null }: BillingLinkConfigProps) => {
	const packageInfo = useSelector(packageSelectors.getPackageInfo)

	const link = linkMap[to]
	const localLink: LocalLink = link.getLocal(
		selectedPackage ?? packageInfo?.name ?? PackageName.Basic,
		configurationType,
	)

	return useMemo(() => ({ localLink }), [localLink])
}
