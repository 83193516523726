export enum ProductNotificationType {
	ChatbotConversationOver75 = 'chatbotConversationOver75',
	ChatbotConversationOver90 = 'chatbotConversationOver90',
	ChatbotConversationLimitReached = 'chatbotConversationLimitReached',
	LivechatConversationOver75 = 'livechatConversationOver75',
	LivechatConversationOver90 = 'livechatConversationOver90',
	LivechatConversationOver95 = 'livechatConversationOver95',
	LivechatConversationLimitReached = 'livechatConversationLimitReached',
	AiConversationOver75 = 'aiConversationOver75',
	AiConversationOver90 = 'aiConversationOver90',
	AiConversationLimitReached = 'aiConversationLimitReached',
}
export type LocalStorageItem = {
	type: ProductNotificationType
	time: string
	limit?: number
}
