import { CurrencyCode, PackageInterval, PackageLogAction, PaymentType, SubscriptionApi } from 'models'

export interface CheckoutErrorMetaData {
	message?: string
	data?: Record<string, unknown> | null
	action?: PackageLogAction
}

export type CheckoutError = Pick<CheckoutErrorMetaData, 'data' | 'message'>

export interface CheckoutStatusData {
	status: 'success' | 'error'
	meta?: CheckoutErrorMetaData
}

export interface CheckoutStatusModalLogData {
	action: PackageLogAction
	message: string
	data?: Record<string, unknown>
}

export type CheckoutAllowedIntervals = {
	[PackageInterval.Month]: boolean
	[PackageInterval.Year]: boolean
}

export type CheckoutAllowedPaymentMethod = typeof PaymentType.CreditCard | typeof PaymentType.Paypal

export type CheckoutAllowedPackageName = SubscriptionApi.CalculateQueryPackage

export enum CheckoutType {
	NewSubscription = 'new_subscription',
	UpdateSubscription = 'update_subscription',
	ShopifySubscription = 'shopify_subscription',
	FlashDealNewSubscription = 'flash_deal_new_subscription',
	FlashDealUpdateSubscription = 'flash_deal_update_subscription',
	StripeNewSubscription = 'stripe_new_subscription',
	StripeUpdateSubscription = 'stripe_update_subscription',
}

export enum CheckoutStep {
	Configuration = 'configuration',
	BillingInfo = 'billingInfo',
	Payment = 'payment',
	PaymentUpdate = 'paymentUpdate',
	Shopify = 'shopify',
}

export enum CheckoutStepPlaceholder {
	Packages = 'packages',
}

export type CheckoutNavigationStep = CheckoutStep | CheckoutStepPlaceholder

export type CheckoutStepInfo = {
	number: number
	name: CheckoutNavigationStep
	title: string
}

type CheckoutSteps<T extends CheckoutNavigationStep> = Record<T, CheckoutStepInfo>

export type NewSubscriptionSteps = CheckoutSteps<
	CheckoutStepPlaceholder.Packages | CheckoutStep.Configuration | CheckoutStep.Payment | CheckoutStep.BillingInfo
>

export type UpdateSubscriptionSteps = CheckoutSteps<
	CheckoutStepPlaceholder.Packages | CheckoutStep.Configuration | CheckoutStep.PaymentUpdate
>

export type ShopifySubscriptionSteps = CheckoutSteps<CheckoutStepPlaceholder.Packages | CheckoutStep.Shopify>

export type FlashDealNewSubscriptionSteps = CheckoutSteps<
	CheckoutStep.Configuration | CheckoutStep.Payment | CheckoutStep.BillingInfo
>
export type FlashDealUpdateSubscriptionSteps = CheckoutSteps<CheckoutStep.Configuration | CheckoutStep.PaymentUpdate>

export type CombinedCheckoutSteps =
	| NewSubscriptionSteps
	| UpdateSubscriptionSteps
	| ShopifySubscriptionSteps
	| FlashDealNewSubscriptionSteps
	| FlashDealUpdateSubscriptionSteps

export enum DiscountType {
	Individual = 'individual',
	Remaining = 'remaining',
	Yearly = 'yearly',
	FlashDealPackage = 'flashDealPackage',
	FlashDealExtraAgent = 'flashDealExtraAgent',
	SpecialOffer = 'specialOffer',
}

export type Discount = {
	type: DiscountType
	count?: number
	value: number
	title: string
	titleParams?: Record<string, string>
}

export enum ConfigurationItemType {
	Agent = 'agent',
	Package = 'package',
	Chatbot = 'chatbot',
	LivechatConversation = 'livechatConversation',
	AiConversation = 'aiConversation',
}

export type AllowedConfigurationItems =
	| ConfigurationItemType.Agent
	| ConfigurationItemType.Chatbot
	| ConfigurationItemType.LivechatConversation
	| ConfigurationItemType.AiConversation

export interface SubscriptionCalculationHook {
	isCalculationLoaded: boolean
	currencyCode: CurrencyCode
	packagePrice: number
	extraAgentsCount: number
	extraChatbotConversationsCount: number
	extraLivechatConversationsCount: number
	extraAiConversationsCount: number
	extraAgentPriceTotalUntaxed: number
	chatbotConversationsBatchPriceTotalUntaxed: number
	livechatConversationsBatchPriceTotalUntaxed: number
	aiConversationsBatchPriceTotalUntaxed: number
	vatRate: number
	vatValue: number
	priceFinalUntaxed: number
	priceFinalTaxed: number
	priceBeforeDiscount: number
	amountDue?: number
	balanceApplied?: number
	isCalculatePending: boolean
	hasPaymentAmount: boolean
	isPriceDifferent: boolean
	hasDifferentPriceForExtraAgent: boolean
	discounts: Discount[]
	hasDiscounts: boolean
	hasOverpayment: boolean
	nextBillingDate: string | null
	nextBillingAmount?: number | null
	isAgentsLoading: boolean
	sumExtraAgentsPrice: number
	isChatbotsLoading: boolean
	sumExtraChatbotsPrice: number
	flashDealBasePriceUntaxed: number | null
	flashDealExtraAgentPriceUntaxed: number | null
	isLivechatConversationsLoading: boolean
	sumExtraLivechatConversationsPrice: number
	isAiConversationsLoading: boolean
	sumExtraAiConversationsPrice: number
}
