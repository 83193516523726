import { lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Router, Switch } from 'react-router-dom'

import { FeatureFlag } from 'models'
import { UserPreference } from 'shared/constants/userPreference'
import { useLocalStorage } from 'shared/hooks/useLocalStorage'
import { isMobilePlatform } from 'shared/utils/isMobilePlatform'
import { routes } from 'configuration/routes'
import { allowAdminUser } from 'utils/auth'
import { history } from 'utils/history'
import { appSelectors } from 'modules/app'
import { useFeatureFlag } from 'modules/features'
import { onboardingSelectors } from 'modules/onboarding'
import { userSelectors } from 'modules/user'

import { ChatWidget } from 'components/chatWidget/ChatWidget'
import { AppLayout } from 'components/layout/AppLayout'
import AuthorizedBillingRoute from 'components/router/AuthorizedBillingRoute'
import AuthorizedRoute from 'components/router/AuthorizedRoute'
import RouterRedirect from 'components/router/RouterRedirect'
import { Alert } from 'shared/components/core/Alert'
import { Box } from 'shared/components/core/Box'
import { Loader } from 'shared/components/core/Loader'

const AppModals = lazy(() => import(/* webpackChunkName: "appModals" */ 'components/modals/AppModals'))
const DocumentTitle = lazy(() => import(/* webpackChunkName: "routesInit" */ './DocumentTitle'))
const ProductNews = lazy(() => import(/* webpackChunkName: "routesInit" */ 'components/productNews/ProductNews'))
const MobilePlatformsPage = lazy(
	() => import(/* webpackChunkName: "routesInit" */ 'components/mobilePlatforms/MobilePlatformsPage'),
)
const AppNotifications = lazy(
	() => import(/* webpackChunkName: "appNotifications" */ 'components/notifications/AppNotifications'),
)

const Onboarding = lazy(() => import(/* webpackChunkName: "onboarding" */ './pages/Onboarding/Onboarding'))
const Conversations = lazy(
	() => import(/* webpackChunkName: "conversations", webpackPrefetch: true */ './pages/Conversations/Conversations'),
)
const UltimateFallback = lazy(
	() => import(/* webpackChunkName: "ultimate" */ './pages/Billing/Ultimate/UltimateFallback'),
)
const Customers = lazy(() => import(/* webpackChunkName: "customers" */ './pages/Customers/Customers'))
const Statistics = lazy(() => import(/* webpackChunkName: "statistics" */ './pages/Statistics/Statistics'))
const Automations = lazy(() => import(/* webpackChunkName: "automations" */ './pages/Automations/Automations'))
const AiAutomations = lazy(() => import(/* webpackChunkName: "aiAutomations" */ './pages/Automations/AiAutomations'))
const SmartHub = lazy(() => import(/* webpackChunkName: "smartHub" */ './pages/SmartHub/SmartHub'))
const Affiliate = lazy(() => import(/* webpackChunkName: "affiliate" */ './pages/Affiliate/Affiliate'))

const Settings = lazy(() => import(/* webpackChunkName: "settings" */ './pages/Settings/Settings'))
const Billing = lazy(() => import(/* webpackChunkName: "billing" */ './pages/Billing/Billing'))
const StyleGuide = lazy(() => import(/* webpackChunkName: "styleGuide" */ './pages/StyleGuide/StyleGuide'))
const FlashDeal = lazy(() => import(/* webpackChunkName: "flashDeal" */ './pages/FlashDeal/FlashDeal'))

const isEnvDevelopment = process.env.NODE_ENV === 'development'
const skipOnboarding = process.env.APP_SKIP_ONBOARDING === 'true'

// routes definitions
const BillingRoutes = () => {
	return (
		<Suspense fallback={<Loader />}>
			<Billing />
			<AppModals />
		</Suspense>
	)
}

const FlashDealRoutes = () => {
	return (
		<Suspense fallback={<Loader />}>
			<FlashDeal />
		</Suspense>
	)
}

const StyleGuideRoutes = () => {
	return (
		<Suspense fallback={<Loader />}>
			<StyleGuide />
		</Suspense>
	)
}

const UltimateRoute = () => {
	return (
		<Suspense fallback={<Loader />}>
			<UltimateFallback />
		</Suspense>
	)
}

const AppRoutes = () => {
	const [storedMobileState, setStoredMobileState] = useLocalStorage(UserPreference.MobilePlatformsPage, '')
	const isHiddenOnMobile = storedMobileState !== ''
	const hasEnabledAiChatbots = useFeatureFlag(FeatureFlag.AiChatbots)

	const isMobile = isMobilePlatform()
	const showMobilePage = isMobile && !isHiddenOnMobile

	const handleClickContinue = () => {
		setStoredMobileState('hidden')
	}

	if (showMobilePage)
		return (
			<Suspense fallback={<Loader />}>
				<MobilePlatformsPage onClick={handleClickContinue} />
			</Suspense>
		)
	return (
		<>
			<AppLayout>
				<Suspense fallback={<Loader />}>
					<Switch>
						<Route path={routes.smartHub.path}>
							<SmartHub />
						</Route>
						<AuthorizedRoute path={routes.affiliate.path} onAuth={allowAdminUser} redirectPath={routes.smartHub.path}>
							<Affiliate />
						</AuthorizedRoute>
						<Route path={routes.conversations.path}>
							<Conversations />
						</Route>
						<Redirect from={routes.contactDetail.oldPath} to={routes.contactDetail.path} />
						<Redirect from={routes.contacts.oldPath} to={routes.contacts.path} />
						<Redirect from={routes.visitors.oldPath} to={routes.visitors.path} />
						<Route path={routes.customers.path}>
							<Customers />
						</Route>
						<AuthorizedRoute path={routes.statistics.path} onAuth={allowAdminUser}>
							<Statistics />
						</AuthorizedRoute>
						{/* IMPORTANT: Do not delete without making sure everyone in ss-dev (slack) knows */}
						<Redirect from={routes.chatbots.oldPath} to={routes.chatbots.path} />
						<Redirect from={routes.chatbots.oldPathV2} to={routes.chatbots.path} />
						<AuthorizedRoute path={routes.automations.path} onAuth={allowAdminUser}>
							<Automations />
						</AuthorizedRoute>
						{hasEnabledAiChatbots && (
							<AuthorizedRoute path={routes.aiAutomations.path} onAuth={allowAdminUser}>
								<AiAutomations />
							</AuthorizedRoute>
						)}
						<Route path={routes.settings.path}>
							<Settings />
						</Route>
						<Route path="*">
							<RouterRedirect to={routes.conversations.path} />
						</Route>
					</Switch>
				</Suspense>
			</AppLayout>
			<Suspense fallback={<Loader />}>
				<AppModals />
				<AppNotifications />
				<ProductNews />
			</Suspense>
		</>
	)
}

export const Routes = () => {
	const appInitError = useSelector(appSelectors.getAppInitError)
	const isOnboardingFinished = useSelector(onboardingSelectors.getIsFinished)
	const isAvatarUploadRequired = useSelector(userSelectors.isAvatarUploadRequired)

	const shouldSkipOnboarding = isEnvDevelopment && skipOnboarding
	const showOnboarding = !shouldSkipOnboarding && (isAvatarUploadRequired || !isOnboardingFinished)

	if (appInitError) {
		return (
			<Box p={2}>
				<Alert
					status="error"
					title="App init error"
					description="The application failed to start because error occurred while loading configuration."
					showIcon
				/>
			</Box>
		)
	}

	return (
		<Router history={history}>
			<Suspense fallback={<Loader />}>
				{showOnboarding ? (
					<Onboarding />
				) : (
					<>
						<DocumentTitle />
						<Switch>
							<AuthorizedBillingRoute path={routes.billing.path} onAuth={allowAdminUser}>
								<BillingRoutes />
							</AuthorizedBillingRoute>
							<AuthorizedBillingRoute path={routes.flashDeal.path} onAuth={allowAdminUser}>
								<FlashDealRoutes />
							</AuthorizedBillingRoute>
							<Route path={routes.styleGuide.path}>
								<StyleGuideRoutes />
							</Route>
							<AuthorizedRoute path={routes.ultimate.path} onAuth={allowAdminUser}>
								<UltimateRoute />
							</AuthorizedRoute>
							<Route path="*">
								<AppRoutes />
							</Route>
						</Switch>
						<ChatWidget />
					</>
				)}
			</Suspense>
		</Router>
	)
}
