import { MouseEvent, ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { PackageName } from 'models'
import { TranslationService as T } from 'shared/services'
import { navigateTo } from 'utils'
import { agentsSelectors } from 'modules/agents'
import { ConfigurationItemType } from 'modules/checkout'
import { userSelectors } from 'modules/user'

import { Box, CButton, CButtonProps, Tooltip } from 'core'
import RouterLink from 'components/router/RouterLink'

import { BillingLinkType, LocalLink, useBillingLink } from './useBillingLink'

type BillingLinkProps = {
	children: ReactNode
	to: BillingLinkType
}

export const BillingLink = ({ children, to, ...rest }: BillingLinkProps) => {
	const { localLink } = useBillingLink({ to })
	return (
		<RouterLink to={localLink.path} params={localLink.params} {...rest}>
			{children}
		</RouterLink>
	)
}

type BillingButtonLinkProps = {
	to: BillingLinkType
	selectedPackage?: PackageName
	configurationType?: ConfigurationItemType | null
} & CButtonProps

export const BillingButtonLink = ({
	to,
	selectedPackage,
	configurationType = null,
	...rest
}: BillingButtonLinkProps) => {
	const { localLink } = useBillingLink({ to, selectedPackage, configurationType })
	const isUserAdmin = useSelector(userSelectors.isUserAdmin)
	const accountOwner = useSelector(agentsSelectors.getAccountOwner)

	return (
		<BillingButtonLinkTemplate
			isUserAdmin={isUserAdmin}
			accountOwnerEmail={accountOwner?.email ?? null}
			localLink={localLink}
			{...rest}
		/>
	)
}

type BillingButtonLinkTemplateProps = {
	isUserAdmin: boolean
	accountOwnerEmail: string | null
	localLink: LocalLink
} & CButtonProps

export const BillingButtonLinkTemplate = ({
	onClick,
	isUserAdmin,
	accountOwnerEmail,
	localLink,
	...rest
}: BillingButtonLinkTemplateProps) => {
	const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
		onClick && onClick(e)
		navigateTo(localLink.path, localLink.params)
	}

	return (
		<Tooltip
			content={
				isUserAdmin
					? null
					: T.translate('upsell.agent.limited.warning', {
							email: accountOwnerEmail ?? '',
					  })
			}
		>
			<Box w={rest.w || rest.width}>
				<CButton {...rest} isDisabled={!isUserAdmin} onClick={handleClick} />
			</Box>
		</Tooltip>
	)
}
