import { combineReducers } from '@reduxjs/toolkit'

import { account } from 'modules/account'
import { accountSegment } from 'modules/accountSegment'
import { addons } from 'modules/addons'
import { affiliate } from 'modules/affiliate'
import { agents } from 'modules/agents'
import { aiAssist } from 'modules/aiAssist'
import { aiChatbots } from 'modules/aiChatbots'
import { aiChatbotStatistics } from 'modules/aiChatbotStatistics'
import { app } from 'modules/app'
import { autoMessages } from 'modules/autoMessages'
import { autoMessageStatistics } from 'modules/autoMessageStatistics'
import { billingInfo } from 'modules/billingInfo'
import { billingProfile } from 'modules/billingProfile'
import { channels } from 'modules/channels'
import { chatbotBuilder } from 'modules/chatbotBuilder'
import { chatbotDetail } from 'modules/chatbotDetail'
import { chatbotIdentities } from 'modules/chatbotIdentities'
import { chatbots } from 'modules/chatbots'
import { chatbotSettings } from 'modules/chatbotSettings'
import { chatbotStatistics } from 'modules/chatbotStatistics'
import { chatbotTemplates } from 'modules/chatbotTemplates'
import { chatbotWizard } from 'modules/chatbotWizard'
import { chatbotWorkflow } from 'modules/chatbotWorkflow'
import { chatDetail } from 'modules/chatDetail'
import { chats } from 'modules/chats'
import { chatsFilter } from 'modules/chatsFilter'
import { chatsSlice } from 'modules/chatsSlice'
import { checkout } from 'modules/checkout'
import { consents } from 'modules/consents'
import { contacts } from 'modules/contacts'
import { conversationsStatistics } from 'modules/conversationsStatistics'
import { countries } from 'modules/countries'
import { features } from 'modules/features'
import { flashDeal } from 'modules/flashDeal'
import { groups } from 'modules/groups'
import { gtm } from 'modules/gtm'
import { installMethods } from 'modules/installMethods'
import { integrations } from 'modules/integrations'
import { invitations } from 'modules/invitations'
import { invoices } from 'modules/invoices'
import { messages } from 'modules/messages'
import { mobileSdk } from 'modules/mobileSdk'
import { notifications } from 'modules/notifications'
import { officeHours } from 'modules/officeHours'
import { onboarding } from 'modules/onboarding'
import { packageInfo } from 'modules/package'
import { packages } from 'modules/packages'
import { paymentMethod } from 'modules/paymentMethod'
import { productNews } from 'modules/productNews'
import { properties } from 'modules/properties'
import { restrictions } from 'modules/restrictions'
import { shortcuts } from 'modules/shortcuts'
import { smartHub } from 'modules/smartHub'
import { sources } from 'modules/sources'
import { statistics } from 'modules/statistics'
import { statisticsFilter } from 'modules/statisticsFilter'
import { stripe } from 'modules/stripe'
import { subscription } from 'modules/subscription'
import { surveys } from 'modules/surveys'
import { systemModal } from 'modules/systemModal'
import { systemNotification } from 'modules/systemNotification'
import { tags } from 'modules/tags'
import { tagsStatistics } from 'modules/tagsStatistics'
import { textarea } from 'modules/textarea'
import { training } from 'modules/training'
import { triggers } from 'modules/triggers'
import { upsell } from 'modules/upsell'
import { usage } from 'modules/usage'
import { user } from 'modules/user'
import { visitors } from 'modules/visitors'
import { widget } from 'modules/widget'
import { widgetPreview } from 'modules/widgetPreview'
import { widgetTranslations } from 'modules/widgetTranslations'
import { wireTransfer } from 'modules/wireTransfer'

export type DashboardState = ReturnType<typeof rootReducer>

const rootReducer = combineReducers({
	account,
	accountSegment,
	addons,
	affiliate,
	agents,
	aiAssist,
	aiChatbots,
	aiChatbotStatistics,
	app,
	autoMessages,
	autoMessageStatistics,
	billingInfo,
	billingProfile,
	channels,
	chatbotIdentities,
	chatbots,
	chatbotDetail,
	chatbotBuilder,
	chatbotTemplates,
	chatbotSettings,
	chatbotStatistics,
	chatbotWizard,
	chatbotWorkflow,
	chatDetail,
	chats,
	chatsSlice,
	chatsFilter,
	checkout,
	consents,
	contacts,
	conversationsStatistics,
	countries,
	features,
	flashDeal,
	gtm,
	restrictions,
	groups,
	integrations,
	installMethods,
	invitations,
	invoices,
	messages,
	mobileSdk,
	notifications,
	officeHours,
	systemNotification,
	package: packageInfo,
	packages,
	paymentMethod,
	productNews,
	onboarding,
	shortcuts,
	smartHub,
	statistics,
	statisticsFilter,
	stripe,
	subscription,
	surveys,
	systemModal,
	tags,
	tagsStatistics,
	textarea,
	training,
	triggers,
	upsell,
	user,
	usage,
	visitors,
	properties,
	widget,
	widgetTranslations,
	wireTransfer,
	sources,
	widgetPreview,
})

export default rootReducer
