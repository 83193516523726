import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppDispatch, AppThunkAction, DashboardState } from 'types'
import { AiUsage } from 'shared/models/Usage'
import { isNumber } from 'shared/utils'
import { updateDataLayerMonthlyServed } from 'modules/dataLayer'
import { packageSelectors } from 'modules/package'
import { SourceFormType } from 'modules/sources'
import {
	hideProductNotification,
	notificationSelectors,
	ProductNotificationType,
	showProductNotification,
} from 'modules/systemNotification'
import { isNotificationAllowed } from 'modules/systemNotification/utils'
import { isAllowedNotificationInMonth } from 'modules/usage/utils'

import { usageApi } from './api'
import { MAX_USAGE_THRESHOLD_75, MAX_USAGE_THRESHOLD_90, MAX_USAGE_THRESHOLD_95 } from './constants'
import {
	AiReplyAssistLimitType,
	FeatureUsageLimitsType,
	MaxChatbotConversationsLimitType,
	MaxServedChatsLimitType,
} from './types'

export const fetchUsage = createAsyncThunk('usage/FETCH', async (_, thunkAPI) => {
	try {
		const response = await usageApi.fetchUsage()
		const dispatch = thunkAPI.dispatch as AppDispatch
		dispatch(updateDataLayerMonthlyServed(response.monthly_served_conversations.value))
		return response
	} catch (error) {
		return thunkAPI.rejectWithValue(error)
	}
})

export const fetchAiUsage = createAsyncThunk('usage/FETCH_AI', async () => {
	return usageApi.fetchAiUsage()
})

export const updateMonthlyServedConversations =
	(count: number): AppThunkAction =>
	(dispatch) => {
		dispatch(setMonthlyServed(count))
		dispatch(updateDataLayerMonthlyServed(count))
		dispatch(checkUsageLimits())
	}

export const checkUsageLimits = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const chatbotConversations = getChatbotConversationsLimits(state)
	const livechatConversations = getMonthlyServedLimits(state)
	const aiConversations = getAiConversationsLimits(state)

	const hasProductNotification = !!notificationSelectors.getProductNotificationType(state)

	// Livechat conversations
	if (
		livechatConversations.isOverLimit &&
		isNotificationAllowed(ProductNotificationType.LivechatConversationLimitReached, livechatConversations.limit)
	) {
		dispatch(showProductNotification(ProductNotificationType.LivechatConversationLimitReached))
		return
	}
	if (
		livechatConversations.isOverThreshold95 &&
		isNotificationAllowed(ProductNotificationType.LivechatConversationOver95, livechatConversations.limit)
	) {
		dispatch(showProductNotification(ProductNotificationType.LivechatConversationOver95))
		return
	}
	if (
		livechatConversations.isOverThreshold90 &&
		isNotificationAllowed(ProductNotificationType.LivechatConversationOver90, livechatConversations.limit) &&
		livechatConversations.isAllowedNotificationInMonth
	) {
		dispatch(showProductNotification(ProductNotificationType.LivechatConversationOver90))
		return
	}
	if (
		livechatConversations.isOverThreshold75 &&
		isNotificationAllowed(ProductNotificationType.LivechatConversationOver75, livechatConversations.limit) &&
		livechatConversations.isAllowedNotificationInMonth
	) {
		dispatch(showProductNotification(ProductNotificationType.LivechatConversationOver75))
		return
	}

	// AI conversations
	if (
		aiConversations.isOverLimit &&
		isNotificationAllowed(ProductNotificationType.AiConversationLimitReached, aiConversations.limit)
	) {
		dispatch(showProductNotification(ProductNotificationType.AiConversationLimitReached))
		return
	}
	if (
		aiConversations.isOverThreshold90 &&
		isNotificationAllowed(ProductNotificationType.AiConversationOver90, aiConversations.limit)
	) {
		dispatch(showProductNotification(ProductNotificationType.AiConversationOver90))
		return
	}
	if (
		aiConversations.isOverThreshold75 &&
		isNotificationAllowed(ProductNotificationType.AiConversationOver75, aiConversations.limit)
	) {
		dispatch(showProductNotification(ProductNotificationType.AiConversationOver75))
		return
	}

	// Chatbot conversations
	if (
		chatbotConversations.isOverLimit &&
		isNotificationAllowed(ProductNotificationType.ChatbotConversationLimitReached, chatbotConversations.limit)
	) {
		dispatch(showProductNotification(ProductNotificationType.ChatbotConversationLimitReached))
		return
	}
	if (
		chatbotConversations.isOverThreshold90 &&
		isNotificationAllowed(ProductNotificationType.ChatbotConversationOver90, chatbotConversations.limit)
	) {
		dispatch(showProductNotification(ProductNotificationType.ChatbotConversationOver90))
		return
	}
	if (
		chatbotConversations.isOverThreshold75 &&
		isNotificationAllowed(ProductNotificationType.ChatbotConversationOver75, chatbotConversations.limit)
	) {
		dispatch(showProductNotification(ProductNotificationType.ChatbotConversationOver75))
	}

	// Hide notification if it becomes irrelevant after the usage changes
	else if (hasProductNotification) {
		dispatch(hideProductNotification())
	}
}

export const aiReplyAssistCounterIncrement = (): AppThunkAction => (dispatch, getState) => {
	const currentCounter = getAiReplyAssistCounter(getState())
	const newCounter = Number(currentCounter) + 1
	dispatch(setAiReplyAssistCounter(newCounter))
}

export type UsageState = typeof initialState
export type UsageRootState = Pick<DashboardState, 'usage'>

const initialState = {
	monthlyServedConversations: 0,
	chatbotConversations: 0,
	aiReplyAssistCounter: 0,
	aiConversations: 0,
	aiUsage: null as null | AiUsage.Account,
}

const usageSlice = createSlice({
	name: 'usage',
	initialState,
	reducers: {
		setMonthlyServed: (state, { payload }: PayloadAction<number>) => {
			state.monthlyServedConversations = payload
		},
		setChatbotConversations: (state, { payload }: PayloadAction<number>) => {
			state.chatbotConversations = payload
		},
		setAiReplyAssistCounter: (state, { payload }: PayloadAction<number>) => {
			state.aiReplyAssistCounter = payload
		},
		setAiConversations: (state, { payload }: PayloadAction<{ limit: number | null; usage: number }>) => {
			if (state.aiUsage) {
				state.aiUsage.conversations.used = payload.usage
				if (isNumber(payload.limit)) state.aiUsage.conversations.limit = payload.limit
			}
		},
		setAiBonusConversations: (state, { payload }: PayloadAction<{ limit: number | null; usage: number }>) => {
			if (state.aiUsage) {
				state.aiUsage.bonusConversations.used = payload.usage
				if (isNumber(payload.limit)) state.aiUsage.bonusConversations.limit = payload.limit
			}
		},
		setAiSourceFeedCount: (state, { payload }: PayloadAction<{ limit: number | null; usage: number }>) => {
			if (state.aiUsage) {
				state.aiUsage.sourceFeeds.used = payload.usage
				if (isNumber(payload.limit)) state.aiUsage.sourceFeeds.limit = payload.limit
			}
		},
		setAiSourceWebCount: (state, { payload }: PayloadAction<{ limit: number | null; usage: number }>) => {
			if (state.aiUsage) {
				state.aiUsage.sourceWebsites.used = payload.usage
				if (isNumber(payload.limit)) state.aiUsage.sourceWebsites.limit = payload.limit
			}
		},
		setAiPreviewMessages: (state, { payload }: PayloadAction<{ limit: number | null; usage: number }>) => {
			if (state.aiUsage) {
				state.aiUsage.previewMessages.used = payload.usage
				if (isNumber(payload.limit)) state.aiUsage.previewMessages.limit = payload.limit
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchUsage.fulfilled, (state, { payload }) => {
			state.monthlyServedConversations = payload.served_conversation.value
			state.chatbotConversations = payload.bot_conversation.value
			state.aiReplyAssistCounter = payload.ai_assist.value
			state.aiConversations = payload.ai_conversation.value
		})
		builder.addCase(fetchAiUsage.fulfilled, (state, { payload }) => {
			state.aiUsage = payload
		})
	},
})

const { reducer, actions } = usageSlice
export const {
	setMonthlyServed,
	setChatbotConversations,
	setAiConversations,
	setAiBonusConversations,
	setAiReplyAssistCounter,
	setAiSourceFeedCount,
	setAiSourceWebCount,
	setAiPreviewMessages,
} = actions
export default reducer

const getMonthlyServed = (state: UsageRootState) => state.usage.monthlyServedConversations
const getChatbotConversations = (state: UsageRootState) => state.usage.chatbotConversations
const getAiReplyAssistCounter = (state: UsageRootState) => state.usage.aiReplyAssistCounter
const getAiUsage = (state: UsageRootState) => state.usage.aiUsage

const getMonthlyServedLimits = createSelector(
	[getMonthlyServed, packageSelectors.getPackageInfo],
	(monthlyServed, packageInfo): MaxServedChatsLimitType => {
		const limit = packageInfo?.livechatConversationsLimit ?? null
		const usage = limit ? monthlyServed / limit : null
		const isOverThreshold75 = usage ? usage >= MAX_USAGE_THRESHOLD_75 && usage < MAX_USAGE_THRESHOLD_90 : false
		const isOverThreshold90 = usage ? usage >= MAX_USAGE_THRESHOLD_90 && usage < MAX_USAGE_THRESHOLD_95 : false
		const isOverThreshold95 = usage && limit ? usage >= MAX_USAGE_THRESHOLD_95 && monthlyServed < limit : false
		const isOverLimit = limit ? limit <= monthlyServed : false
		const hasLastToLimit = limit ? limit === monthlyServed + 1 : false

		return {
			count: monthlyServed,
			limit,
			isOverThreshold75,
			isOverThreshold90,
			isOverThreshold95,
			isOverLimit,
			hasLastToLimit,
			isAllowedNotificationInMonth: limit ? isAllowedNotificationInMonth(monthlyServed, limit) : false,
		}
	},
)

const getChatbotConversationsLimits = createSelector(
	[getChatbotConversations, packageSelectors.getPackageInfo],
	(chatbotConversations, packageInfo): MaxChatbotConversationsLimitType => {
		const limit = packageInfo?.chatbotConversationsLimit ?? null
		const usage = limit ? chatbotConversations / limit : null
		const isOverThreshold75 = usage && limit ? usage >= MAX_USAGE_THRESHOLD_75 && usage < MAX_USAGE_THRESHOLD_90 : false
		const isOverThreshold90 = usage && limit ? usage >= MAX_USAGE_THRESHOLD_90 && chatbotConversations < limit : false
		const isOverLimit = limit ? limit <= chatbotConversations : false

		return {
			count: chatbotConversations,
			limit,
			isOverThreshold75,
			isOverThreshold90,
			isOverLimit,
		}
	},
)

const getAiConversationsLimits = createSelector([getAiUsage], (aiUsage): FeatureUsageLimitsType => {
	const aiConversations = aiUsage?.conversations.used ?? 0
	const limit = aiUsage?.conversations.limit ?? null

	const usage = limit ? aiConversations / limit : null
	const isOverThreshold75 = usage ? usage >= MAX_USAGE_THRESHOLD_75 && usage < MAX_USAGE_THRESHOLD_90 : false
	const isOverThreshold90 = usage && limit ? usage >= MAX_USAGE_THRESHOLD_90 && aiConversations < limit : false
	const isOverLimit = limit ? limit <= aiConversations : false

	return {
		count: aiConversations,
		limit,
		isOverThreshold75,
		isOverThreshold90,
		isOverLimit,
	}
})

const shouldShowChatbotConversationsLimitAlert = createSelector(
	[getChatbotConversationsLimits],
	({ isOverLimit, isOverThreshold75, isOverThreshold90 }): boolean => {
		return isOverLimit || isOverThreshold75 || isOverThreshold90
	},
)

const getAiReplyAssistLimits = createSelector(
	[getAiReplyAssistCounter, packageSelectors.getPackageInfo],
	(counter, packageInfo): AiReplyAssistLimitType => {
		const limit = packageInfo?.aiAssistsLimit ?? 0
		return {
			count: counter,
			limit,
			isOverLimit: limit ? counter >= limit : false,
		}
	},
)

const getBonusAiConversationsLimits = createSelector([getAiUsage], (aiUsage): FeatureUsageLimitsType => {
	const count = aiUsage?.bonusConversations.used ?? 0
	const limit = aiUsage?.bonusConversations.limit ?? null
	const usage = limit ? count / limit : null
	const isOverThreshold75 = usage ? usage >= MAX_USAGE_THRESHOLD_75 && usage < MAX_USAGE_THRESHOLD_90 : false
	const isOverThreshold90 = usage && limit ? usage >= MAX_USAGE_THRESHOLD_90 && count < limit : false
	const isOverLimit = limit ? limit <= count : false

	return {
		count,
		limit,
		isOverThreshold75,
		isOverThreshold90,
		isOverLimit,
	}
})

const getAiSourcesLimits = createSelector(
	[getAiUsage],
	(
		aiUsage,
	): {
		limits: Record<SourceFormType, { isReached: boolean }>
		isLimitReached: boolean
	} => {
		const limitFeeds = aiUsage?.sourceFeeds.limit ?? null
		const limitWebsites = aiUsage?.sourceWebsites.limit ?? null

		const countSourcesFeeds = aiUsage?.sourceFeeds.used ?? 0
		const countSourcesWebsites = aiUsage?.sourceWebsites.used ?? 0

		const isFeedsLimitReached = limitFeeds ? countSourcesFeeds >= limitFeeds : false
		const isWebsitesLimitReached = limitWebsites ? countSourcesWebsites >= limitWebsites : false

		return {
			limits: {
				[SourceFormType.Xml]: { isReached: isFeedsLimitReached },
				[SourceFormType.Web]: { isReached: isWebsitesLimitReached },
			},
			isLimitReached: isWebsitesLimitReached && isFeedsLimitReached,
		}
	},
)

const getIsAiPreviewLimitReached = createSelector([getAiUsage], (aiUsage): boolean => {
	const limitPreviewMessages = aiUsage?.previewMessages.limit ?? null
	const usagePreviewMessages = aiUsage?.previewMessages.used ?? 0

	return limitPreviewMessages ? usagePreviewMessages >= limitPreviewMessages : false
})

export const usageSelectors = {
	getMonthlyServedLimits,
	getChatbotConversationsLimits,
	getAiConversationsLimits,
	shouldShowChatbotConversationsLimitAlert,
	getAiReplyAssistLimits,
	getBonusAiConversationsLimits,
	getAiSourcesLimits,
	getIsAiPreviewLimitReached,
}
