import { ComponentProps } from 'react'
import { FormattedNumber, IntlProvider } from 'react-intl'

import { CurrencyCode } from 'models'
import { ConfigurationService } from 'shared/services'

type Props = {
	currencyCode: CurrencyCode
	locale?: string
} & ComponentProps<typeof FormattedNumber>

const IntlCurrency = ({ currencyCode, locale, value, ...props }: Props) => {
	const { currencyLocale } = ConfigurationService.getData()
	const localeValue = locale ?? currencyLocale
	if (!localeValue) return null

	return (
		<IntlProvider locale={localeValue} key={localeValue}>
			{/* eslint-disable-next-line react/style-prop-object */}
			<FormattedNumber {...props} style="currency" value={value} currency={currencyCode} />
		</IntlProvider>
	)
}

export default IntlCurrency
